import styled from 'styled-components';

export const ServiceCardWrapper = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 20px;

  position: relative;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
  transition: background-color 0.3s ease;
  margin-right: 22px;
  :hover {
    cursor: pointer;
    > h2 {
      color: #ffffff;
    }
    > p {
      color: #ffffff;
    }
    > button {
      opacity: 1;
    }
    > :first-child {
      transform: scale(1.06, 1.13);
      background: #624de3;
      border-color: transparent;
    }
  }
`;

export const ServiceCardContainer = styled.div`
  position: relative;
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.8s cubic-bezier(0.65, 0.05, 0.36, 1);
  opacity: 0;
  transform: translateY(5%);
  ${({ visible }) =>
    visible &&
    `
    opacity: 1;
    transform: translateY(0%)
`}
`;

export const ServiceImage = styled.img`
  height: 48%;
  max-width: 80%;
  width: auto;
  object-fit: contain;
  padding-top: 10%;
  padding-left: 10px;
  z-index: 2;
  @media (max-height: 900px) {
    height: 45%;
  }
`;

export const Title = styled.h2`
  z-index: 2;
  font-family: Eina-sb;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  color: #3f3f3f;
  margin-top: 3%;
  padding-right: 2px;
  margin-bottom: 3px;
  white-space: pre-line;
  ${(props) => props.theme.laptopM`
    font-size: 17px;
  `}
`;

export const SubTitle = styled.p`
  opacity: 0.8;
  font-family: Helvetica-n;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.1px;
  text-align: center;
  color: #8d8d8d;
  white-space: pre-line;
  padding: 0 15px;
  @media (max-height: 1000px) {
    font-size: 13px;
  }
  @media (max-height: 950px) {
    padding: 0 10px;
    /* font-size: 12px; */
  }
  @media (max-width: 1605px) {
    font-size: 12px;
  }
  ${(props) => props.theme.laptopM`
    font-size: 11px;
  `}
`;

export const SeeMoreButton = styled.button`
  position: absolute;
  cursor: inherit;
  width: 72px;
  min-height: 42px;
  border-radius: 18px;
  box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  opacity: 0;
  /* margin: 0 auto; */
  align-self: flex-end;
  /* margin-top: auto; */
  z-index: 2;
  margin-bottom: 6%;
  bottom: -6%;
  left: calc(50% - 36px);
  transition: all 0.4s ease-in;
`;

export const Layer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  border-radius: 20px;
  border: solid 1px #e5eaec;
  transition: 0.4s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  z-index: 0;
`;
