import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import {
  TimelineContainer,
  TimelineWrapper,
  StartIconWrapper,
  Caption,
  EmojiIcon,
  TimelineContent,
  LinesList,
  Line,
  StepsList,
  LinesLayer,
  DragButtonWrapper,
  DragIcon,
  DragLabel,
  TimelineInnerContainer,
  EmojiEndWrapper,
  DragButtonContainer,
} from './styles';
import PageCaption from '../common/PageCaption';
import { steps } from './steps';
import TimelineStep from '../timeline-step/TimelineStep';
import { AnimationWrapper } from '../common/styles';

const Timeline = () => {
  const [visible, setVisible] = useState({
    caption: false,
    section: false,
    button: false,
  });

  return (
    <TimelineContainer Container id="process">
      <VisibilitySensor
        partialVisibility={true}
        onChange={(isVisible) => {
          if (isVisible && !visible.section) {
            setVisible((curr) => ({ ...curr, section: true }));
          }
        }}
      >
        <TimelineInnerContainer>
          <TimelineWrapper>
            <Caption>
              <StartIconWrapper>
                <EmojiIcon src={'/assets/icons/emoji-start.svg'} alt="emoji" />
              </StartIconWrapper>
              <VisibilitySensor
                onChange={(isVisible) => {
                  if (isVisible && !visible.caption) {
                    setVisible((curr) => ({ ...curr, caption: true }));
                  }
                }}
              >
                <AnimationWrapper visible={visible.caption}>
                  <PageCaption label="How We Craft" />
                </AnimationWrapper>
              </VisibilitySensor>
            </Caption>
            <TimelineContent>
              <StepsList>
                {steps.map((step) => {
                  return (
                    <TimelineStep
                      step={step}
                      key={step.label}
                      visible={visible.section}
                    />
                  );
                })}
                <EmojiEndWrapper>
                  <EmojiIcon
                    src={'/assets/icons/emoji-end.svg'}
                    end="true"
                    alt="emoji"
                  />
                </EmojiEndWrapper>
              </StepsList>
              <LinesList>
                <Line />
                <Line />
                <Line />
                <Line />
                <Line />
                <Line />
                <Line />
                <LinesLayer />
              </LinesList>
            </TimelineContent>

            <DragButtonContainer>
              <VisibilitySensor
                onChange={(isVisible) => {
                  if (isVisible && !visible.button) {
                    setVisible((curr) => ({ ...curr, button: true }));
                  }
                }}
              >
                <DragButtonWrapper visible={visible.button}>
                  <DragIcon src={'/assets/icons/drag.svg'} alt="drag" />
                  <DragLabel visible={visible.button}>
                    Hold & Drag screen to see timeline processes
                  </DragLabel>
                </DragButtonWrapper>
              </VisibilitySensor>
            </DragButtonContainer>
          </TimelineWrapper>
        </TimelineInnerContainer>
      </VisibilitySensor>
    </TimelineContainer>
  );
};

export default Timeline;
