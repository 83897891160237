import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import {
  ServiceCardContainer,
  ServiceCardWrapper,
  ServiceImage,
  Title,
  SubTitle,
  SeeMoreButton,
  Layer,
} from './styles';

import ArrowIcon from '../common/ArrowIcon';

const ServiceCard = ({ data, clickHandler }) => {
  const [visible, setVisible] = useState(false);
  const { name, description, serviceImg } = data;

  return (
    <VisibilitySensor
      partialVisibility={true}
      onChange={(isVisible) => {
        if (isVisible && !visible) {
          setVisible(true);
        }
      }}
    >
      {/* <ServiceAnimationWrapper visible={visible}> */}
      <ServiceCardContainer visible={visible}>
        <ServiceCardWrapper onClick={clickHandler}>
          <Layer />
          <ServiceImage src={serviceImg} alt={name} />
          <Title>{name}</Title>
          <SubTitle>{description}</SubTitle>
          <SeeMoreButton>
            <ArrowIcon />
          </SeeMoreButton>
        </ServiceCardWrapper>
      </ServiceCardContainer>
      {/* </ServiceAnimationWrapper> */}
    </VisibilitySensor>
  );
};

export default ServiceCard;
