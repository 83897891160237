import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyles = createGlobalStyle`
  ${normalize}
  @font-face {
    font-family: Eina-b;
    src: url(/assets/fonts/eina02-bold-webfont.woff) format('woff'),
    url(/assets/fonts/eina02-bold-webfont.woff2) format('woff2');
  }
  @font-face {
    font-family: Eina-bi;
    src: url(/assets/fonts/eina02-bolditalic-webfont.woff) format('woff'),
    url(/assets/fonts/eina02-bolditalic-webfont.woff2) format('woff2');
  }
  @font-face {
    font-family: Eina-l;
    src: url(/assets/fonts/eina02-light-webfont.woff) format('woff'),
    url(/assets/fonts/eina02-light-webfont.woff2) format('woff2');
  }
  @font-face {
    font-family: Eina-li;
    src: url(/assets/fonts/eina02-lightitalic-webfont.woff) format('woff'),
    url(/assets/fonts/eina02-lightitalic-webfont.woff2) format('woff2');

  }
  @font-face {
    font-family: Eina-r;
    src: url(/assets/fonts/eina02-regular-webfont.woff) format('woff'),
    url(/assets/fonts/eina02-regular-webfont.woff2) format('woff2');
  }
  @font-face {
    font-family: Eina-ri;
    src: url(/assets/fonts/eina02-regularitalic-webfont.woff) format('woff'),
    url(/assets/fonts/eina02-regularitalic-webfont.woff2) format('woff2');
  }
  @font-face {
    font-family: Eina-sb;
    src: url(/assets/fonts/eina02-semibold-webfont.woff) format('woff'),
    url(/assets/fonts/eina02-semibold-webfont.woff2) format('woff2');
  }
  @font-face {
    font-family: Eina-sbi;
    src: url(/assets/fonts/eina02-semibolditalic-webfont.woff) format('woff'),
    url(/assets/fonts/eina02-semibolditalic-webfont.woff2) format('woff2');
  }
  @font-face {
    font-family: Helvetica-n;
    src: url(/assets/fonts/helveticaneuelt-webfont.woff) format('woff'),
    url(/assets/fonts/helveticaneuelt-webfont.woff2) format('woff2');
  }  
  @font-face {
    font-family: Helvetica-nm;
    src: url(/assets/fonts/helveticaneue-medium-webfont.woff) format('woff'),
    url(/assets/fonts/helveticaneue-medium-webfont.woff2) format('woff2');
  }
  a {
    text-decoration: none;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style-type: none;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;

  }
  img {
    user-select: none;
    pointer-events: none;
  }
  body {
    background: #ffffff;
    &::-webkit-media-controls {
    display: none !important;
    opacity: 0;
    -webkit-appearance: none;
    scroll-behavior: smooth;
  }
  video {
    :-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
  }
    :-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
  }
  :-webkit-media-controls-overlay-play-button {
    display: none !important;
  }
  :-webkit-media-controls-enclosure {
  display:none !important;
  }
    }
  }

`;

export default GlobalStyles;
