import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import {
  JobListWrapper,
  JobListContainer,
  JobListHeading,
  Caption,
  SubCaption,
  JobsWrapper,
  MobileCaption,
} from './styles';
import { jobs } from './jobs';
import Job from '../../components/job/Job';
import { MobileContentShadow } from '../home/styles';
import { AnimationWrapper } from '../../components/common/styles';

const JobList = ({ contentVisible }) => {
  return (
    <>
      <MobileContentShadow visible={contentVisible} />
      <JobListWrapper contentVisible={contentVisible}>
        <JobListContainer>
          <JobListHeading>
            <VisibilitySensor>
              {({ isVisible }) => (
                <AnimationWrapper visible={isVisible}>
                  <Caption>
                    We craft
                    <br /> is building the Future of computing
                  </Caption>
                </AnimationWrapper>
              )}
            </VisibilitySensor>
            <VisibilitySensor>
              {({ isVisible }) => (
                <AnimationWrapper visible={isVisible}>
                  <MobileCaption>
                    We Craft is building
                    <br />
                    The future of computing
                  </MobileCaption>
                </AnimationWrapper>
              )}
            </VisibilitySensor>
            <VisibilitySensor>
              {({ isVisible }) => (
                <AnimationWrapper visible={isVisible}>
                  <SubCaption>
                    We believe that by equipping people with the best tools to
                    solve their own problems we can tackle the whole world's
                    problems better, together. We're always looking for talented
                    humans who are interested in building the future alongside
                    us.
                  </SubCaption>
                </AnimationWrapper>
              )}
            </VisibilitySensor>
          </JobListHeading>
          <JobsWrapper>
            {jobs.map((j, i) => {
              return <Job key={j.title} job={j} />;
            })}
          </JobsWrapper>
        </JobListContainer>
      </JobListWrapper>
    </>
  );
};

export default JobList;
