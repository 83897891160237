import styled from 'styled-components';

export const ContentContainer = styled.div`
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
`;

export const ContentWrapper = styled.div`
  transition: left 0.6s cubic-bezier(0.23, 1, 0.32, 1.2);
  left: calc(-100% - 35px);
  ${(props) => props.theme.tabletL`
  ${
    props.visible &&
    `
    transform: scale(0.8);
    overflow-y: auto;
    position: absolute;
    top: 49px;
    left: -61%;
    width: 100%;
    height: 90vh;
    border-radius: 20px;
    z-index: 90;
    pointer-events: none;
    ::-webkit-scrollbar {
    display: none;
    }
    @media (max-height: 340px) {
      top: 75px;
      height: 65vh
    }
  `
  }
  ${(props) => props.theme.mobileL`
    ${
      props.visible &&
      `
      height: 85vh;
    `
    }
  `}
`}
  ${({ isLoading }) => isLoading && 'height: 100vh; overflow-y: hidden'}
  scroll-behavior: smooth;
`;

export const MobileContentShadow = styled.div`
  transition: left 0.3s ease-out;
  left: -100%;
  position: absolute;
  width: 270px;
  height: 65vh;
  opacity: 0.6;
  border-radius: 20px;
  box-shadow: 2px 4px 14px 0 rgba(0, 0, 0, 0.1);
  background-color: #bdc0cc;
  display: none;
  top: 143px;
  z-index: 90;
  ${(props) => props.theme.tabletL`
    display: block;
    visibility: hidden;
    ${
      props.visible &&
      `visibility: visible;
      left: 0;
    `
    }
  `}
  ${(props) => props.theme.mobileL`
    width: 143px;
    top: 150px;
    height: 60vh;
  `}
   @media (max-height: 340px) {
    top: 120px;
    height: 50vh;
  }
`;
