import React from 'react';
import {
  InputWrapper,
  StyledInput,
  InputPlaceholder,
  InputError,
  InputContainer,
} from './styles';

const Input = ({ placeholder, small, value, onChange, name, error }) => {
  const formatError = string => string && string.charAt(0).toUpperCase() + string.slice(1)
  return (
    <InputContainer small={small}>
      <InputWrapper>
        <StyledInput onChange={onChange} name={name} value={value} />
        <InputPlaceholder value={value}>{placeholder}</InputPlaceholder>
      </InputWrapper>
      <InputError>{formatError(error)}</InputError>
    </InputContainer>
  );
};

export default Input;
