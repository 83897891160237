import React from 'react';
import { ButtonContainer, ButtonLabel } from './styles';

const Button = ({ label, onClick, disabled, sent, children }) => {
  return (
    <ButtonContainer onClick={onClick} disabled={disabled} sent={sent}>
      {label ? <ButtonLabel>{label}</ButtonLabel> : children}
    </ButtonContainer>
  );
};

export default Button;
