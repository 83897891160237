import styled, { css } from 'styled-components';

// export const MenuShowAnimation = keyframes`
//   from {
//     top: 0;
//   }
//   to {
//     height: 100vh;
//   }
// `;

// export const MenuHideAnimation = keyframes`
//   from {
//     height: 100vh
//   }
//   to {
//     height: 0;
//   }
// `;

export const TabletLogoWrapper = styled.div`
  ${(props) => props.theme.mobileL`
    > div {
      display: none;
    }
  `}
`;

export const MobileNavBarContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;

  background-image: linear-gradient(to bottom, #ffffff, #e9ecfa);
  background-size: cover;
  top: -150%;
  left: 0;
  display: none;
  overflow-y: hidden;
  opacity: 0;
  ${(props) => props.theme.tabletL`
    display: block;
  `}
  transition: opacity 0.6s ease-in-out;

  ${({ open }) => {
    if (open) {
      return css`
        top: 0;
        opacity: 1;
      `;
    }
  }}
`;

export const MobileNavBarWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('/assets/images/nav-oval-top.svg'),
    url('/assets/images/nav-oval-bottom.svg');
  background-position: top right, bottom left;
  background-repeat: no-repeat;
  position: relative;
`;

export const CloseIconWrapper = styled.div`
  /* position: absolute;
  top: 37px;
  right: 0; */
  padding: 10px;
  margin-right: -10px;
`;

export const CloseIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export const NavbarMobileContentWrapper = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  transition: right 0.6s cubic-bezier(0.23, 1, 0.32, 1.2);
  @media all and (orientation: landscape) {
    top: 0px;
  }
  ${({ contentVisible, theme }) =>
    contentVisible &&
    css`
      right: 9.5%;
      ${theme.mobileL`
      right: 24px;
      `}
    `}
`;

export const MobileMenu = styled.nav`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 26px;
`;
export const MobileMenuList = styled.ul``;

export const MobileMenuListItem = styled.li`
  font-family: Eina-sb;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.19px;
  text-align: right;
  color: #323232;
  color: #9da1b4;
  margin-bottom: 15px;
  cursor: pointer;
  @media (max-height: 340px) {
    font-size: 16px;
  }
`;

export const MobileNavBarHeading = styled.div`
  z-index: 50;
  width: 81%;
  position: absolute;
  top: 0%;
  left: 9.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  ${(props) => props.theme.mobileL`
    height: 70px;
    width: calc(100% - 48px);
    left: 24px;
  `}
`;
