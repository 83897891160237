import styled from 'styled-components';

export const DescriptionContainer = styled.div`
  opacity: 0;
  position: absolute;
  right: 0;
  width: 292px;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(8, 27, 96, 0.1);
  background-color: #ffffff !important;
  padding: 15px 21px 19px 21px;
  top: 100%;
  /* bottom: 100%; */
  font-family: Helvetica-n;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  color: #909090;
  white-space: pre-line;
  /* transition: opacity 0.3s ease; */
  transition: top 0.5s cubic-bezier(0.65, 0.05, 0.36, 1),
    opacity 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
  pointer-events: none;
  z-index: 3;

  ::before {
    content: '';
    background: inherit;
    transform: rotate(45deg);
    height: 16px;
    width: 16px;
    border-radius: 2px;
    position: absolute;
    top: -5px;
    left: calc(50% - 8px);
    box-shadow: 0 -40px 20px 0 rgba(8, 27, 96, 0.1);
  }
`;

export const List = styled.ul``;

export const ListItem = styled.li`
  ::before {
    padding-top: 1px;
    content: '•';
    position: absolute;
    left: 0.7em;
    font-size: 1.1em;
  }
`;
