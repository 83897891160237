import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 81%;
  max-width: 1552px;
  margin: 0 auto;
  ${(props) => props.theme.mobileL`
    width: calc(100% - 48px);
  `}
`;

export const ButtonContainer = styled.button`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  :hover {
    opacity: 0.8;
  }
  ${({ sent }) =>
    sent &&
    `
    background: gray;
  `}
  ${({ disabled, sent }) => (disabled || sent) && `cursor: not-allowed`}
`;

export const ButtonLabel = styled.span`
  font-family: Eina-b;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: 0.4px;
  color: #ffffff;
  ${(props) => props.theme.mobileL`
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.35;
  `}
`;

export const PageCaptionLabel = styled.h2`
  font-family: Eina-sb;
  font-size: 40px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #323232;
  ${(props) => props.theme.mobileL`
  font-size: 24px;
  letter-spacing: -0.9px;
  z-index: 1;
  `}
`;

export const StyledInput = styled.input`
  width: 100%;
  font-family: Eina-sb;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #624de3;
  padding-bottom: 15px;
  ::placeholder {
    display: none;
  }
`;

export const InputPlaceholder = styled.span`
  font-family: Eina-sb;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8a8d9c;
  position: absolute;
  left: 0;
  bottom: 15px;
  transition: all 0.2s ease;
  pointer-events: none;
  ${StyledInput}:focus ~ & {
    font-size: 12px;
    bottom: 35px;
  }
  ${({ value }) =>
    value &&
    `
    font-size: 12px;
    bottom: 35px;
  `}
`;

export const InputWrapper = styled.div`
  position: relative;
  /* width: ${({ small }) => (small ? '304px' : '639px')}; */
  /* ${({ small }) => small && ``} */

  width: 100%;
  border-bottom: 1px solid #e5eaec;
`;

export const ArrowWrapper = styled.div`
  color: inherit;
  ${({ opposite }) =>
    opposite && `transform: rotate(-180deg);  margin-bottom: 6px;`}
`;

export const InputError = styled.p`
  margin-top: 10px;
  color: red;
  font-family: Helvetica-n;
  font-size: 12px;
  height: 15px;
`;

export const InputContainer = styled.div`
  /* max-width: 639px; */
  flex: 1;
  :first-child {
    margin-right: ${({ small }) => (small ? '31px' : 0)};
  }
  ${(props) => props.theme.tabletL`
    ${
      props.small &&
      `
      :first-child {
        margin-right: 0;
        margin-bottom: 14px;
      }
     width: 100%;
    `
    }
  `}
`;

export const AnimationWrapper = styled.div`
  height: fit-content;
  overflow: hidden;
  > :first-child {
    transform: translateY(101%);
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }

  ${({ visible, customOpacity }) =>
    visible &&
    css`
      > :first-child {
        opacity: ${customOpacity ? customOpacity : '1'};
        transform: translateY(0%);
      }
    `}
`;

export const AppContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const ScrollWrapper = styled.div``;
