import styled from 'styled-components';

export const Nav = styled.nav`
  flex: 1;
  ${(props) => props.theme.tabletL`
    display: none;
  `}
`;

export const NavList = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const NavListItem = styled.li`
  margin-right: 3.48%;
  /* 10.73%; */

  /* 51px; */

  &:last-child {
    margin-right: 0;
  }
`;

export const NavItem = styled.a`
  display: block;
  letter-spacing: 0.15px;
  font-family: Eina-sb;
  font-size: 16px;
  color: #5b5f72;
  /* font-weight: 600; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  cursor: pointer !important;
  white-space: nowrap;
  :hover {
    color: ${({ theme }) => theme.colors.primary};
    ${({ isButton, theme }) =>
      isButton &&
      `
      background-color: ${theme.colors.primary};
      color: #ffffff;
    `}
  }

  ${({ isButton, theme }) =>
    isButton &&
    `
    width: 150px;
    height: 54px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(13, 27, 67, 0.1);
    background-color: #ffffff;
    color: ${theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    `}
  transition: all 0.2s ease;
`;
