import React, { useState, createContext } from 'react';

const ContentLoadContext = createContext();
export default ContentLoadContext;

export const ContentLoadProvider = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const state = {
    isLoaded,
    setIsLoaded,
  };

  return (
    <ContentLoadContext.Provider value={state}>
      {children}
    </ContentLoadContext.Provider>
  );
};
