import React from 'react';
import { StepWrapper, StepLine, StepLabel } from './styles';
import TimelineStepDescription from '../timeline-step-description/TimelineStepDescription';

const TimelineStep = ({ step, visible }) => {
  return (
    <StepWrapper {...step} visible={visible}>
      <StepLine />
      <StepLabel>{step.label}</StepLabel>
      <StepLine />
      {step.description && (
        <TimelineStepDescription description={step.description} />
      )}
    </StepWrapper>
  );
};

export default TimelineStep;
