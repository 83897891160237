export const jobs = [
  {
    title: 'Full Stack Engineer, Payments ',
    date: 'Apr 26, 2020 11:05 AM',
    locations: [
      {
        label: 'San Francisco',
        color: 'orange',
      },
    ],
    redirectParam: 'full-stack-engineer-payments',
  },
  {
    title: 'Javascript Engineer',
    date: 'Apr 26, 2020 11:05 AM',
    locations: [
      {
        label: 'Tbilisi, Georgia',
        color: 'purple',
      },
    ],
    redirectParam: 'javascript-engineer',
  },
  {
    title: 'Brand Designer',
    date: 'Apr 26, 2020 11:05 AM',
    locations: [
      {
        label: 'San Francisco',
        background: '#efd8d3',
        color: 'orange',
      },
      {
        label: 'London',
        color: 'blue',
      },
    ],
    redirectParam: 'brand-designer',
  },
  {
    title: 'UI / UX Designer, Product Designer',
    date: 'Apr 26, 2020 11:05 AM',
    locations: [
      {
        label: 'Tbilisi, Georgia',
        color: 'purple',
      },
    ],
    redirectParam: 'ui-ux-designer',
  },
];
