import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import * as FontFaceObserver from 'fontfaceobserver';

import ContentLoadContext from '../../contexts/ContentLoad';

import {
  LoaderWrapper,
  LabelWrapper,
  Label,
  SubLabel,
  SubLabelWrapper,
  SubLabelsContainer,
} from './styles';

const labels = ['Transparently', 'In time', 'Smart things'];

const Loader = ({ fontsLoading, videoLoading }) => {
  const { isLoaded, setIsLoaded } = useContext(ContentLoadContext);
  const [localFontLoading, setLocalFontLoading] = useState(true);
  const history = useHistory();
  const { pathname } = history.location;
  const isTablet = useMediaQuery({
    query: '(max-width : 940px)',
  });

  useEffect(() => {
    if (pathname === '/' && !isTablet) {
      setIsLoaded(!fontsLoading || !videoLoading);
    } else {
      setIsLoaded(!fontsLoading);
    }
  }, [setIsLoaded, pathname, isTablet, fontsLoading, videoLoading]);

  const einaB = new FontFaceObserver('Eina-b');
  const einaR = new FontFaceObserver('Eina-r');

  Promise.all([einaB.load(null, 0), einaR.load(null, 0)]).then(() => {
    setLocalFontLoading(false);
  });

  return (
    <LoaderWrapper isLoading={!isLoaded}>
      <LabelWrapper isLoading={localFontLoading}>
        <Label>We Craft</Label>
        <SubLabelWrapper>
          <SubLabelsContainer>
            {labels.map((l, i) => {
              return <SubLabel key={i}>{l}</SubLabel>;
            })}
          </SubLabelsContainer>
        </SubLabelWrapper>
      </LabelWrapper>
    </LoaderWrapper>
  );
};

export default Loader;
