import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import VisibilitySensor from 'react-visibility-sensor';

import {
  BannerContainer,
  BannerWrapper,
  BannerDescWrapper,
  BannerCaption,
  MainCaption,
  BannerDescription,
  ButtonWrapper,
  BannerVideo,
  VideoWrapper,
  GradientLayer,
  MobileOvalWrapper,
  MobileOvalImg,
  VideoPoster,
  // Emoji,
} from './styles';
import { Container, AnimationWrapper } from '../common/styles';
import Button from '../common/Button';
import { navigateToContent } from '../../utils/helpers';

const Banner = ({ setVideoLoading, loading }) => {
  const isTablet = useMediaQuery({
    query: '(max-width: 940px)',
  });
  const [visible, setVisible] = useState({
    caption: false,
    subCaption: false,
    button: isTablet ? true : false,
  });

  return (
    <BannerContainer id="banner">
      <Container>
        <BannerWrapper>
          <BannerDescWrapper>
            <VisibilitySensor
              onChange={(isVisible) => {
                if (isVisible && !visible.caption) {
                  setTimeout(() => {
                    setVisible((curr) => ({ ...curr, caption: true }));
                  }, 500);
                }
              }}
            >
              <AnimationWrapper visible={!loading && visible.caption}>
                <BannerCaption>
                  <MainCaption>WeCraft</MainCaption> Smart Things{' '}
                </BannerCaption>
              </AnimationWrapper>
            </VisibilitySensor>
            <VisibilitySensor
              onChange={(isVisible) => {
                if (isVisible && !visible.subCaption) {
                  setTimeout(() => {
                    setVisible((curr) => ({ ...curr, subCaption: true }));
                  }, 500);
                }
              }}
            >
              <AnimationWrapper
                visible={!loading && visible.subCaption}
                customOpacity={isTablet ? 1 : 0.4}
              >
                <BannerDescription>
                  Daily progress making company with transparent workspace
                </BannerDescription>
                {/* <BannerDescription>
                  Believe that by equipping people with the best tools to solve
                  their own problems we can tackle the whole world's problems
                  better, together. We're always looking for talented humans who
                  are interested
                </BannerDescription> */}
              </AnimationWrapper>
            </VisibilitySensor>
            <VisibilitySensor
              onChange={(isVisible) => {
                if (isVisible && !visible.button) {
                  setTimeout(() => {
                    setVisible((curr) => ({ ...curr, button: true }));
                  }, 500);
                }
              }}
            >
              <AnimationWrapper visible={visible.button}>
                <ButtonWrapper>
                  <Button
                    label="HOW WE CRAFT"
                    onClick={(e) => navigateToContent(e, 'process')}
                  />
                </ButtonWrapper>
              </AnimationWrapper>
            </VisibilitySensor>
          </BannerDescWrapper>

          <VideoWrapper>
            {isTablet ? (
              <VideoPoster src="/assets/images/video-poster.png" alt="poster" />
            ) : (
              <BannerVideo
                playsInline
                autoPlay
                muted
                loop
                id="banner-video"
                onCanPlayThrough={() => setVideoLoading(false)}
              >
                <source src="/assets/videos/main3.mp4" type="video/mp4" />
              </BannerVideo>
            )}
            <GradientLayer />
          </VideoWrapper>
        </BannerWrapper>
      </Container>
      <MobileOvalWrapper>
        <MobileOvalImg src="/assets/images/mobile-banner-oval.svg" alt="oval" />
      </MobileOvalWrapper>
    </BannerContainer>
  );
};

export default Banner;
