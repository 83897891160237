import React from 'react';

import { useHistory } from 'react-router-dom';
import { Nav, NavItem, NavList, NavListItem } from './styles';
import { navigateToContent } from '../../utils/helpers';

export const list = [
  {
    label: 'About',
    mobileLabel: 'About us',
    scrollId: 'banner',
  },
  {
    label: 'Process',
    mobileLabel: 'Process',
    scrollId: 'process',
  },
  {
    label: 'Projects',
    mobileLabel: 'Our Projects',
    scrollId: 'projects',
  },
  {
    label: 'Team',
    mobileLabel: 'Team members',
    scrollId: 'team',
  },
  {
    label: 'Service',
    mobileLabel: 'Service',
    scrollId: 'service',
  },
  // {
  //   label: 'Job list',
  //   mobileLabel: 'Job list',
  //   redirectUrl: '/jobs',
  // },
  {
    label: 'Contact',
    mobileLabel: 'Contact',
    scrollId: 'contact',
    isButton: true,
  },
];

const Navbar = ({ setDirection }) => {
  const history = useHistory();
  return (
    <Nav>
      <NavList>
        {list.map((item) => {
          return (
            <NavListItem key={item.label}>
              <NavItem
                href={item.scrollId}
                isButton={item.isButton}
                onClick={(e) => {
                  if (item.redirectUrl) {
                    history.push(item.redirectUrl);
                    return;
                  }
                  navigateToContent(e, item.scrollId, history, setDirection);
                }}
              >
                {item.label}
              </NavItem>
            </NavListItem>
          );
        })}
      </NavList>
    </Nav>
  );
};

export default Navbar;
