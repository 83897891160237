import React, { useEffect, useRef, useState, useContext } from 'react';

import Banner from '../../components/banner/Banner';
import Timeline from '../../components/timeline/Timeline';
import Projects from '../../components/projects/Projects';
import {
  ContentContainer,
  ContentWrapper,
  MobileContentShadow,
} from './styles';
import Services from '../../components/services/Services';
import Footer from '../../components/footer/Footer';
import Team from '../../components/team/Team';
import { scrollToCurrentPosition } from '../../utils/helpers';
import ContentLoadContext from '../../contexts/ContentLoad';

const Home = ({
  contentVisible,
  currentPosition,
  setVideoLoading,
  setCurrentPosition,
}) => {
  const contentRef = useRef();
  const { isLoaded } = useContext(ContentLoadContext);

  useEffect(() => {
    scrollToCurrentPosition(contentRef, currentPosition, contentVisible);
  }, [currentPosition, contentVisible, setCurrentPosition]);
  const [chosenService, setChosenService] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);

  return (
    <>
      <MobileContentShadow visible={contentVisible} />
      <ContentWrapper
        visible={contentVisible}
        ref={contentRef}
        isLoading={!isLoaded}
      >
        <Banner setVideoLoading={setVideoLoading} loading={!isLoaded} />
        <ContentContainer>
          <Timeline />
          <Projects />
          <Team />
          <Services
            setChosenService={setChosenService}
            setSelectedServices={setSelectedServices}
          />
        </ContentContainer>
        <Footer
          chosenService={chosenService}
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
        />
      </ContentWrapper>
    </>
  );
};

export default Home;
