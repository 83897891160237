// <?xml version="1.0" encoding="UTF-8"?>
import React from 'react';
import { ArrowWrapper } from './styles';

const ArrowIcon = ({ width, height, color, opposite }) => {
  return (
    <ArrowWrapper opposite={opposite}>
      <svg
        width={width || '16px'}
        height={height || '16px'}
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>arrow</title>
        <g
          id="Web"
          stroke="none"
          strokeWidth="1"
          fill={color || '#624de3'}
          fillRule="evenodd"
        >
          <g
            id="service-hover-effects"
            transform="translate(-415.000000, -868.000000)"
            // transform="rotate(45deg)"
            // transform="translate(-868.000000,-415.000000)"
            // fill="#624DE3"
            fill={color}
            fillRule="nonzero"
          >
            <g id="Group-8" transform="translate(12.000000, 95.000000)">
              <g id="Group-7" transform="translate(237.000000, 326.000000)">
                <g id="Group-27" transform="translate(138.000000, 434.000000)">
                  <g id="arrow" transform="translate(28.000000, 13.000000)">
                    <polygon
                      id="Path"
                      points="12.172 7 6.808 1.636 8.222 0.222 16 8 8.222 15.778 6.808 14.364 12.172 9 0 9 0 7"
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </ArrowWrapper>
  );
};

export default ArrowIcon;
