import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { services, budgets } from './lists';
import {
  FooterWrapper,
  FooterContainer,
  ContentWrapper,
  LeftFooter,
  RightFooter,
  LeftFooterCaption,
  AddressNav,
  AddressColumn,
  AddressWrapper,
  AddressLabel,
  AddressLabelDark,
  SocialPlatformsNav,
  SocialPlatformWrapper,
  LeftFooterCopyRightWrapper,
  CopyRightLabel,
  CopyRightLabelBlue,
  RightFooterCaption,
  RightFooterDesc,
  RightFooterDescBlue,
  SectionLabel,
  FooterTag,
  ServicesContainer,
  SendButtonWrapper,
  SmallInputsWrapper,
  TagsNav,
  RightFooterLine,
  TabletAdressNav,
  TabletAdressRow,
  SubmitWrapper,
  CaptionWrapper,
} from './styles';
import {
  // FbSvg,
  UpworkSvg,
  StackOvrflSvg,
  CrunchBaseSvg,
  LinkedinSvg,
  AngelistSvg,
  FreelancerSvg,
} from './icons';
import Button from '../common/Button';
import Input from '../common/Input';
import { InputError } from '../common/styles';
import {
  handleChooseCriteria,
  validate,
  isTouchDetected,
} from '../../utils/helpers';
import Tooltip from '../tooltip/tooltip';

const Footer = ({ chosenService, selectedServices, setSelectedServices }) => {
  const [selectedBudget, setSelectedBudget] = useState([]);

  const servicesList = chosenService
    ? services.filter((item) => item.service === chosenService)
    : services;

  const [values, setValues] = useState({
    name: '',
    email: '',
    details: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
  });

  const [submitted, setSubmitted] = useState(false);
  const [formSent, setFormSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestFailed, setRequestFailed] = useState(false);

  const [iconsHovered, setIconHover] = useState({
    fb: false,
    stack: false,
    upwork: false,
    crunchbase: false,
    linkedin: false,
    angelist: false,
    freelancer: false,
  });

  const formFilled =
    selectedServices?.length ||
    selectedBudget?.length ||
    values.name ||
    values.email ||
    values.details;

  useEffect(() => {
    validate(values, setErrors);
  }, [values]);

  useEffect(() => {
    if (formSent && formFilled) {
      setFormSent(false);
    }
  }, [formFilled, formSent, setFormSent]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (loading || formSent) return;

      setSubmitted(true);

      if (Object.values(errors).find((item) => !!item)) return;
      setLoading(true);

      const serviceLabel = selectedServices.map((s) => s.title).join(', ');
      const budgetLabel = selectedBudget.map((b) => b.title).join(', ');
      let html = `
    <div>
    <h2>Check the details:</h2>
    <p> 
    Name: <strong> ${values.name} </strong
    </p>
    <p> 
    Email: <strong> ${values.email} </strong
    </p>
    `;

      const blocks = [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `New Project Request Received`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Name: ${values.name}`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Email: ${values.email}`,
          },
        },
      ];
      if (values.details) {
        html += `<p>
        Project Details : <strong>  ${values.details} </strong>
        </p>`;

        blocks.push({
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Project Details: ${values.details}`,
          },
        });
      }
      if (selectedServices.length) {
        html += `<p>
        Selected Services: <strong>${serviceLabel}</strong>
        </p>`;
        blocks.push({
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Selected services: ${serviceLabel}`,
          },
        });
      }
      if (selectedBudget.length) {
        html += `
        <p>
        Selected Budgets: <strong>
        ${budgetLabel}
        </strong>
        </p>`;
        blocks.push({
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Selected Budgets: ${budgetLabel}`,
          },
        });
      }

      let slackData = {
        text: 'New project request received',
        blocks: blocks,
      };

      html += '</div>';

      const emailData = {
        name: values.name,
        html,
      };
      await axios({
        method: 'post',
        url: 'https://www.wecraft.ai/.netlify/functions/sendProjectRequest',
        data: { slackData: JSON.stringify(slackData), emailData },
      });

      setValues({
        name: '',
        email: '',
        details: '',
      });
      setSubmitted(false);
      setSelectedBudget([]);
      setSelectedServices([]);
      setLoading(false);
      setFormSent(true);
      setRequestFailed(false);
    } catch (err) {
      console.log('Error: ', JSON.stringify(err));
      setRequestFailed(true);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const returnDate = () => {
    const today = new Date();
    return today.getFullYear();
  };

  const handleHoverEvent = (name, bool) => {
    if (isTouchDetected) return;
    setIconHover({ [name]: bool });
  };

  const handleIconColor = (name) =>
    iconsHovered[name] ? '#FFFFFF' : '#a4a9bd';
  return (
    <FooterContainer id="contact">
      <FooterWrapper>
        <CaptionWrapper>
          <RightFooterCaption>HIRE US</RightFooterCaption>
          <RightFooterDesc>
            A Project with <RightFooterDescBlue>We Craft?</RightFooterDescBlue>
          </RightFooterDesc>
        </CaptionWrapper>
        <ContentWrapper>
          <LeftFooter>
            <div>
              <LeftFooterCaption>Contacts</LeftFooterCaption>
              <AddressNav>
                <AddressColumn>
                  <AddressWrapper>
                    <AddressLabel>
                      <AddressLabelDark>
                        Georgia, Tbilisi, Asatiani 10.
                      </AddressLabelDark>
                    </AddressLabel>
                    <AddressLabel light>0177</AddressLabel>
                    <AddressLabel light>+995 599 532 299</AddressLabel>
                  </AddressWrapper>
{/* 
                  <SocialPlatformsNav>
                    <SocialPlatformWrapper
                      onMouseEnter={() => handleHoverEvent('upwork', true)}
                      onMouseLeave={() => handleHoverEvent('upwork', false)}
                    >
                      <Tooltip content="Upwork" direction="top">
                        <UpworkSvg
                          color={handleIconColor('upwork')}
                          width="25px"
                          heigth="25px"
                        />
                      </Tooltip>
                    </SocialPlatformWrapper>

                    <SocialPlatformWrapper
                      onMouseEnter={() => handleHoverEvent('stack', true)}
                      onMouseLeave={() => handleHoverEvent('stack', false)}
                    >
                      <Tooltip content="Stack Overflow" direction="top">
                        <StackOvrflSvg
                          color={handleIconColor('stack')}
                          width="25px"
                          heigth="25px"
                        />
                      </Tooltip>
                    </SocialPlatformWrapper>

                    <SocialPlatformWrapper
                      onMouseEnter={() => handleHoverEvent('crunchbase', true)}
                      onMouseLeave={() => handleHoverEvent('crunchbase', false)}
                    >
                      <Tooltip content="Crunchbase" direction="top">
                        <CrunchBaseSvg
                          color={handleIconColor('crunchbase')}
                          width="25px"
                          heigth="25px"
                        />
                      </Tooltip>
                    </SocialPlatformWrapper>

                    <SocialPlatformWrapper
                      onMouseEnter={() => handleHoverEvent('linkedin', true)}
                      onMouseLeave={() => handleHoverEvent('linkedin', false)}
                    >
                      <Tooltip content="LinkedIn" direction="top">
                        <LinkedinSvg
                          color={handleIconColor('linkedin')}
                          width="25px"
                          heigth="25px"
                        />
                      </Tooltip>
                    </SocialPlatformWrapper>

                    <SocialPlatformWrapper
                      onMouseEnter={() => handleHoverEvent('angelist', true)}
                      onMouseLeave={() => handleHoverEvent('angelist', false)}
                    >
                      <Tooltip content="AngelList" direction="top">
                        <AngelistSvg
                          color={handleIconColor('angelist')}
                          width="25px"
                          heigth="25px"
                        />
                      </Tooltip>
                    </SocialPlatformWrapper>

                    <SocialPlatformWrapper
                      onMouseEnter={() => handleHoverEvent('freelancer', true)}
                      onMouseLeave={() => handleHoverEvent('freelancer', false)}
                    >
                      <Tooltip content="Freelancer" direction="top">
                        <FreelancerSvg
                          color={handleIconColor('freelancer')}
                          width="25px"
                          heigth="25px"
                        />
                      </Tooltip>
                    </SocialPlatformWrapper>
                  </SocialPlatformsNav> */}
                </AddressColumn>
              </AddressNav>
              <TabletAdressNav>
                <TabletAdressRow>
                  <AddressWrapper>
                    <AddressLabel>
                      <AddressLabelDark>
                        Georgia, Tbilisi, Asatiani 10.
                      </AddressLabelDark>
                    </AddressLabel>
                    <AddressLabel light>0177</AddressLabel>
                    <AddressLabel light>+995 599 532 299</AddressLabel>
                  </AddressWrapper>
                </TabletAdressRow>
                <TabletAdressRow>
                  {/* <SocialPlatformsNav>
                    <SocialPlatformWrapper>
                      <Tooltip content="Upwork" direction="top">
                        <UpworkSvg width="25px" heigth="25px" color="#a4a9bd" />
                      </Tooltip>
                    </SocialPlatformWrapper>

                    <SocialPlatformWrapper>
                      <Tooltip content="Stack Overflow" direction="top">
                        <StackOvrflSvg
                          width="25px"
                          heigth="25px"
                          color="#a4a9bd"
                        />
                      </Tooltip>
                    </SocialPlatformWrapper>

                    <SocialPlatformWrapper>
                      <Tooltip content="Crunchbase" direction="top">
                        <CrunchBaseSvg
                          width="25px"
                          heigth="25px"
                          color="#a4a9bd"
                        />
                      </Tooltip>
                    </SocialPlatformWrapper>

                    <SocialPlatformWrapper>
                      <Tooltip content="LinkedIn" direction="top">
                        <LinkedinSvg
                          width="25px"
                          heigth="25px"
                          color="#a4a9bd"
                        />
                      </Tooltip>
                    </SocialPlatformWrapper>

                    <SocialPlatformWrapper>
                      <Tooltip content="Angelist" direction="top">
                        <AngelistSvg
                          width="25px"
                          heigth="25px"
                          color="#a4a9bd"
                        />
                      </Tooltip>
                    </SocialPlatformWrapper>

                    <SocialPlatformWrapper>
                      <Tooltip content="Freelancer" direction="top">
                        <FreelancerSvg
                          width="25px"
                          heigth="25px"
                          color="#a4a9bd"
                        />
                      </Tooltip>
                    </SocialPlatformWrapper>
                  </SocialPlatformsNav> */}
                </TabletAdressRow>
              </TabletAdressNav>
            </div>
            <LeftFooterCopyRightWrapper>
              <CopyRightLabel>
                <CopyRightLabelBlue>
                  © Wecraft studio {returnDate()} .
                </CopyRightLabelBlue>
              </CopyRightLabel>
              <CopyRightLabel>
                All copyrights are protected by the company wecraft. Control
                your business with us
              </CopyRightLabel>
            </LeftFooterCopyRightWrapper>
          </LeftFooter>
          <RightFooterLine />
          <RightFooter>
            <ServicesContainer>
              <SectionLabel>Services</SectionLabel>
              <TagsNav>
                {servicesList.map((s) => {
                  const { title, id } = s;
                  return (
                    <FooterTag
                      key={id}
                      onClick={() =>
                        handleChooseCriteria(
                          selectedServices,
                          setSelectedServices,
                          s,
                        )
                      }
                      selected={selectedServices.find(
                        (service) => service.id === s.id,
                      )}
                    >
                      {title}
                    </FooterTag>
                  );
                })}
              </TagsNav>
            </ServicesContainer>
            <SectionLabel>Budget in USD</SectionLabel>
            <TagsNav>
              {budgets.map((b) => {
                const { title, id } = b;
                return (
                  <FooterTag
                    key={id}
                    onClick={() =>
                      handleChooseCriteria(selectedBudget, setSelectedBudget, b)
                    }
                    selected={selectedBudget.find(
                      (budget) => budget.id === b.id,
                    )}
                  >
                    {title}
                  </FooterTag>
                );
              })}
            </TagsNav>
            <SmallInputsWrapper>
              <Input
                small
                name="name"
                placeholder="Name"
                value={values.name}
                onChange={handleChange}
                error={submitted && errors.name}
              />
              <Input
                small
                type="email"
                name="email"
                placeholder="E-mail Address"
                value={values.email}
                onChange={handleChange}
                error={submitted && errors.email}
              />
            </SmallInputsWrapper>
            <Input
              name="details"
              placeholder="Project Details (Optional)"
              value={values.details}
              onChange={handleChange}
            />
            {requestFailed && (
              <InputError textTransform="none">
                Message not delivered, please try again.
              </InputError>
            )}
            <SubmitWrapper>
              <SendButtonWrapper>
                <Button
                  label={loading ? 'SENDING...' : formSent ? 'SENT' : 'SEND'}
                  disabled={loading}
                  sent={formSent}
                  onClick={handleSubmit}
                />
              </SendButtonWrapper>
            </SubmitWrapper>
          </RightFooter>
        </ContentWrapper>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
