import styled from 'styled-components';

export const ProjectCardWrapper = styled.div`
  width: 100%;
  height: fit-content;
  border-radius: 27px;
  background-size: cover;
  position: relative;
  cursor: pointer;
  /* -moz-transition: 0.4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -ms-transition: all .4s ease-in-out; */
  ${({ hasMargin }) => hasMargin && `margin-bottom: 300px;`}
  ${(props) => props.theme.tabletL`
    margin-bottom: 150px;
  `}
  ${(props) => props.theme.mobileL`
    margin-bottom: 24px;
  `}
  :hover {
    > :first-child {
      -ms-transform: scale(0.95, 0.95); /* IE 9 */
      transform: scale(0.95, 0.95);
      /* border-radius: 16px; */
    }
    > nav {
      transform: scale(0.95, 0.95);
    }
  }
  ${(props) => props.theme.tabletL`
    :hover {
      > :first-child {
        -ms-transform: unset;
        transform: unset;
        border-radius: unset;
      } 
      > :last-child {
      transform: unset;
    }
    }
  `}
`;

export const Background = styled.img`
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 27px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
`;

export const ProjectHeading = styled.div`
  position: absolute;
  top: ${(props) => props.topPosition || '12%'};
  left: 8.5%;
  margin-right: 12px;

  ${(props) => props.theme.mobileL`
    top: 70px;
    left: 20px;
  `}
  ${(props) => props.theme.mobileS`
    top: 60px;
  `}
`;

export const ProjectIcon = styled.img`
  width: ${({ large }) => (large ? '50%' : '34%')};
  min-width: 140px;
  max-width: 266px;
  height: auto;
  top: 6.28%;
  left: 8.4%;
  position: absolute;
  object-fit: cover;

  ${(props) => props.theme.mobileL`
    width: auto;
    height: 32px;
    object-fit: contain;
    left: 20px;
  `}
  ${(props) => props.theme.mobileS`
    max-width: 200px;
  `}
`;

export const ProjectName = styled.h2`
  font-size: 32px;
  font-family: Eina-b;
  font-stretch: normal;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.6px;
  color: #ffffff;
  ${({ moreMargin }) =>
    moreMargin &&
    `
    margin-bottom: 7px;
    `}
  @media (max-width: 1550px) {
    font-size: 28px;
  }
  @media (max-width: 1400px) {
    font-size: 32px;
  }
  ${(props) => props.theme.laptopM`
    margin-bottom: 5px;
  `}
  @media (max-width: 985px) {
    font-size: 30px;
  }
  ${(props) => props.theme.mobileL`
    font-size: 25px;
    letter-spacing: -0.94px;
    ${
      props.smaller &&
      `font-size: 20px;
    letter-spacing: -0.75px;
    `
    }
  `}
`;

export const ProjectDescription = styled.p`
  width: 100%;
  max-width: 95%;
  opacity: 0.7;
  font-family: Helvetica-nm;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.6px;
  color: #ffffff;
  line-height: 1.33;
  @media (max-width: 1550px) {
    font-size: 18px;
  }
  @media (max-width: 1300px) {
    font-size: 16px;
  }
  ${(props) => props.theme.laptopM`
    font-size: 14px;
  `}
  @media (max-width: 1050px) {
    font-size: 13px;
  }
  ${(props) => props.theme.tabletL`
    font-size: 25px;
  `}
  @media (max-width: 750px) {
    font-size: 22px;
  }
  ${(props) => props.theme.tabletS`
    font-size: 20px;
  `}
  @media (max-width: 590px) {
    font-size: 18px;
  }
  @media (max-width: 480px) {
    font-size: 16px;
  }
  ${(props) => props.theme.mobileL`
      display: block;
      font-size: 15px;
      font-weight: normal;
      line-height: 1.67;
      letter-spacing: -0.45px;
      max-width: 241px;
  `}
  @media (max-width: 400px) {
    max-width: 220px;
  }
  @media (max-width: 380px) {
    font-size: 14px;
    max-width: 190px;
  }
  ${(props) => props.theme.mobileM`
    font-size: 12px;
  `}
`;

export const ProjectTag = styled.div`
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  height: 42px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: ${({ morePadding }) => (morePadding ? '11px 28px' : '11px 20px')};
  font-family: Eina-b;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #383838;
  margin-left: 6px;
  :first-child {
    margin-left: 0;
  }
  white-space: nowrap;
  ${(props) => props.theme.laptopM`
    padding: 9px 15px;
    font-size: 11px;
    height: 34px;
  `}
  ${(props) => props.theme.tabletL`
    height: 50px;
    padding: 15px 18px;
    font-size: 14px;
  `}
  ${(props) => props.theme.tabletS`
    height: 42px;
    padding: 13px 18px;
    font-size: 12px;
  `}
  ${(props) => props.theme.mobileM`
    padding: 9px 15px;
    font-size: 11px;
    height: 34px;
  `}
  ${(props) => props.theme.mobileS`
    display: none;
  `}
`;

export const BoxNav = styled.nav`
  display: flex;
  position: absolute;
  bottom: 6.28%;
  left: 8.4%;
  width: 90%;
  transition: all 0.3s ease-in;
  /* width: 100%; */
  /* ${(props) => props.theme.laptopM`
    display: none;
  `} */
  ${(props) => props.theme.mobileL`
    bottom: 24px;
    left: 20px;
    display: flex;
  `}
`;

export const ProjectTitle = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  color: white;
  font-family: Helvetica-nm;
  margin-bottom: 10px;
  ${(props) => props.theme.laptopL`
    font-size: 23px;
    margin-bottom: 7px;
  `}

  ${(props) => props.theme.laptopM`
    font-size: 20px;
    margin-bottom: 5px;
  `}
  ${(props) => props.theme.laptop`
    font-size: 17px;
  `}
  ${(props) => props.theme.tabletL`
    font-size: 40px;
  `}
  ${(props) => props.theme.tablet`
    font-size: 35px;
  `}
  @media (max-width: 680px) {
    font-size: 33px;
  }
  ${(props) => props.theme.tabletS`
    font-size: 31px;
  `}
  @media (max-width: 590px) {
    font-size: 28px;
  }
  @media (max-width: 535px) {
    font-size: 25px;
  }
  @media (max-width: 480px) {
    font-size: 22px;
  }
  ${(props) => props.theme.mobileL`
    font-size: 25px;
    margin-bottom: 5px;
  `}
  @media (max-width: 420px) {
    font-size: 22px;
  }
  @media (max-width: 380px) {
    font-size: 17px;
  }

  ${(props) => props.theme.mobileS`
    font-size: 17px;
  `}
`;

// export const ContentWrapper = styled.div`
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   left: 0;
//   top: 0;
//   transition: 0.4s ease-in;
//   /* display: flex;
//   flex-direction: column;
//   justify-content: space-between; */
// `;

// export const InnerContent = styled.div`
//   width: 100%;
//   height: 100%;
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// `;

export const ProjectAnimationWrapper = styled.div`
  > div {
    transition: 0.8s cubic-bezier(0.65, 0.05, 0.36, 1);
    opacity: 0;
    transform: translateY(5%);
    cursor: unset;
  }
  ${({ visible }) =>
    visible &&
    `
  > div {
    cursor: pointer;
    opacity: 1;
    transform: translateY(0%)
  }
`}
`;
