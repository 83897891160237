export const constantMembers = [
  {
    avatar: '/assets/images/members/Davit Khaburdzania.png',
    name: 'Davit Khaburdzania',
    position: 'Co founder',
  },
  {
    avatar: '/assets/images/members/Otto vacheishvili-min.png',
    name: 'Oto Vacheishvili',
    position: 'Co founder',
  },
];

export const members = [
  {
    avatar: '/assets/images/members/giorgi jangirashvili-min.png',
    name: 'Giorgi Jangirashvili',
    position: 'Front End Developer',
  },
  {
    avatar: '/assets/images/members/dimitri kevanishvili-min.png',
    name: 'Dimitri Kevanishvili',
    position: 'Back End Developer',
  },
  {
    avatar: '/assets/images/members/Ellene-min.png',
    name: 'Elene Kitsmarishvili',
    position: 'Front End Developer',
  },
  {
    avatar: '/assets/images/members/tornike margishvili-min.png',
    name: 'Tornike Margishvili',
    position: 'Back End Developer',
  },
  {
    avatar: '/assets/images/members/akaki devidze-min.png',
    name: 'Akaki Devidze',
    position: 'QA',
  },
  {
    avatar: '/assets/images/members/saba turdzeladze-min.png',
    name: 'Saba Turdzeladze',
    position: 'Front End Developer',
  },
  {
    avatar: '/assets/images/members/Lily gabadadze-min.png',
    name: 'Lily Gabadadze',
    position: 'Front End Developer',
  },
  {
    avatar: '/assets/images/members/Beqa Paikidze-min.png',
    name: 'Beqa Paikidze',
    position: 'Sound Engineer',
  },
  {
    avatar: '/assets/images/members/nutsa tabatadze-min.png',
    name: 'Nutsa Tabatadze',
    position: 'QA',
  },
  // {
  //   avatar: '/assets/images/members/Revaz Komakhidze-min.png',
  //   name: 'Revaz Komakhidze',
  //   position: 'Back End Developer',
  // },

  {
    avatar: '/assets/images/members/salome sulaberidze-min.png',
    name: 'Salome Sulaberidze',
    position: 'Project Manager',
  },
  {
    avatar: '/assets/images/members/Natia-min.png',
    name: 'Natia Uchaneishvili',
    position: 'Accounting',
  },
  {
    avatar: '/assets/images/members/keit kitsmarishvili-min.png',
    name: 'Keti Kitsmarishvili',
    position: 'UX/UI Designer',
  },
  {
    avatar: '/assets/images/members/lasha lagoshvili-min.png',
    name: 'Lasha Lagoshvili',
    position: 'QA',
  },

  {
    avatar: '/assets/images/members/nika-min.png',
    name: 'Nika Svanidze',
    position: 'Back End Developer',
  },
  // {
  //   avatar: '/assets/images/avatar1.svg',
  //   name: 'Gia Feiqrishvili',
  //   position: 'Devops Cloud Engineer',
  // },

];
