import styled, { css } from 'styled-components';

export const LocationContainer = styled.div`
  width: 128px;
  height: 32px;
  border-radius: 4px;
  background-color: #efd8d3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  :nth-child(2) {
    width: 107px;
  }
  ${({ color }) => {
    switch (color) {
      case 'orange':
        return css`
          background-color: #efd8d3;
          color: #f78235;
        `;
      case 'blue':
        return css`
          background-color: #c9dbf9;
          color: #3590f7;
        `;
      case 'purple':
        return css`
          background-color: #d2cdf5;
          color: #624de3;
        `;
      default:
        return css`
          background-color: #d2cdf5;
          color: #624de3;
        `;
    }
  }}
  ${props => props.theme.mobileL`
    margin-left: unset;
    margin-right: 6px;
  `}
`;

export const LocationLabel = styled.span`
  font-family: Helvetica-nm;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: inherit;
`;
