import styled from 'styled-components';

export const ProjectContainer = styled.section`
  width: 100%;
  /* padding-top: 141px; */
  padding-top: 7.3%;
  background: #ffffff;
  padding-bottom: 67px;
  ${(props) => props.theme.tabletL`
    padding-bottom: 30px;
    `}
  ${(props) => props.theme.mobileL`
    padding-bottom: 91px;
    padding-top: 107px;
  `}
`;

export const ProjectWrapper = styled.div`
  /* padding: 0 85px; */
  /* padding-left: 72px; */
  display: flex;
  justify-content: center;
  ${(props) => props.theme.tabletL`
    flex-direction: column;
  `}
`;

export const Column = styled.div`
  position: relative;
  width: 38.02%;
  margin-right: 9.7%;
  &:last-child {
    margin-right: 0;
  }
  ${(props) => props.theme.tabletL`
    width: 100%;
    margin-right: 0;
  `}
  ${({ left }) =>
    left &&
    `display: flex;
    flex-direction: column;
    justify-content: center;
    `}
`;

export const ProjectSectionHeading = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 33px;
  ${(props) => props.theme.tabletL`
    position: unset;
    margin-bottom: 80px;
  `}

  ${(props) => props.theme.mobileL`
    margin-bottom: 41px;
  `}
`;

export const DesktopWrapper = styled.div`
  ${(props) => props.theme.tabletL`
    display: none;
  `}
`;

export const MobileWrapper = styled.div`
  display: none;
  ${(props) => props.theme.tabletL`
    display: block;
  `}
`;

export const SectionDescription = styled.p`
  max-width: 446px;
  opacity: 0.5;
  font-family: Helvetica-n;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.3px;
  color: #323232;
  margin-top: 14px;
  ${(props) => props.theme.tabletL`
    width: 100%;
    max-width: unset;
  `}
`;

export const DesktopLabelWrapper = styled.span`
  ${(props) => props.theme.tabletL`
  display: none;
 `}
`;
