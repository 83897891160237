export const innerJobs = [
  {
    param: 'full-stack-engineer-payments',
    title: 'Full Stack Engineer, Payments',
    date: 'Apr 26, 2020 11:05 AM',
    locations: [
      {
        label: 'San Francisco',
        color: 'orange',
      },
    ],
    brief: `Acme Corp is seeking a talented individual who possesses infinite curiosity. You'll partner with our head of product to create groundbreaking technology. This is an incredible opportunity to make a meaningful impact on the future of Acme Corp.`,
    responsibilities: [
      'Help guide our overall strategy through design, prototyping, and market research.',
      'Report directly to our head of technology.',
      'Manage a team of 5 technologists. Provide regular feedback and support.',
      'Resolve support requests on a day-to-day basis.',
      'Partner closely with marketing to communicate brand value.',
      'Balance speed and quality, with a focus on tangible results.',
      'Present your roadmap at company-wide checkins.',
    ],
    qualifications: [
      '4+ years experience as a problem solver',
      'Attention to detail',
      'Excellent communication skills',
      'Deep understanding of technology',
      'Proven record of shipping features on time and on budget',
      'Flexible and focussed on solutions',
      'Organized and self-sufficient',
    ],
    benefits: [
      'Health insurance with 100% premium covered',
      'Generous PTO  / sick leave',
      '401(k) plan with employer match',
      'Free lunch and snacks',
      'Annual company retreat',
      'Bring your dog to work 🐶',
    ],
    about: `We believe that by equipping people with the best tools to solve their own problems, we can tackle the whole world's problems better, together.`,
    howToApply: `Does this role sound like a good fit? Email us at jobs@acmecorp.co
    - Include the role's title in your subject line.
    - Send along links that best showcase the relevant things you've built and done.`,
  },
];
