export const navigateToContent = (
  e,
  id,
  history,
  setDirection,
  isMobile,
  setCurrentPosition,
) => {
  if (!isMobile && e) {
    e.preventDefault();
  }
  const navigate = () => {
    const content = document.getElementById(id);
    if (!content) return;

    content.scrollIntoView({ behavior: 'smooth' });
    if (id === 'banner') return;
    if (setDirection) {
      // setTimeout(() => {
      setDirection('down');
      // }, 600);
    }
  };

  if (history && history.location && history.location.pathname !== '/') {
    if (setCurrentPosition) {
      setCurrentPosition(null);
    }
    history.push('/');
    window.scrollTo({ top: 0 });
    setTimeout(() => {
      navigate();
    }, 100);
    return;
  }
  navigate();
};

export const scrollToCurrentPosition = (
  ref,
  currentPosition,
  contentVisible,
) => {
  if (currentPosition && ref.current) {
    if (contentVisible) {
      ref.current.scrollTo({ top: currentPosition });
    } else {
      window.scrollTo(0, currentPosition);
    }
  }
};

export const handleChooseCriteria = (values, setValues, value) => {
  const v = values.find((item) => item.id === value.id);
  let arr = [];
  if (v) {
    arr = values.filter((item) => item.id !== v.id);
  } else {
    arr = [...values, value];
  }
  setValues(arr);
};

export const validate = (values, setErrors) => {
  let errors = {};
  const validation = {
    email: {
      validation: !/\S+@\S+\.\S+/.test(values.email),
      error: 'Email is not Valid',
    },
  };
  for (const key in values) {
    if (key !== 'details') {
      if (!values[key]) {
        errors[key] = `${key} is required`;
      } else if (validation[key] && validation[key].validation) {
        errors[key] = validation[key].error;
      }
    }
  }
  return setErrors(errors);
};


export const isTouchDetected = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0;


// export const DetermineBackgroundColor = (src) => {
//   switch (src) {
//     case '/assets/images/members/Otto vacheishvili-min.png':
//       return colors[2].hexCode;
//     case '/assets/images/members/Davit Khaburdzania-min.svg':
//       return colors[1].hexCode;;
//     default:
//       return GetRandomBackgroundColor();
//   }
// }

// export const GetRandomBackgroundColor = () => {
//   const colorIndex = Math.floor(Math.random() * Math.floor(colors.length));
//   let backgroundColor = colors[colorIndex].hexCode;
//   return backgroundColor;
// }

// const colors = [
//   {name:'Pink',hexCode:"#CF838E"},
//   {name:'Blue',hexCode:"#624DE3"},
//   {name:'Green',hexCode:"#97C7A7"},
//   {name:'Yellow',hexCode:"#F4DE66"},
// ];

