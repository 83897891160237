import styled, { keyframes, css } from 'styled-components';

export const TeamAvatarAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

export const TeamNameAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%)
  }
  20% {
    opacity: 1;
    transform: translateY(0)
  }
  80% {
    opacity: 1;
    transform: translateY(0)
  }
  100% {
    opacity: 0;
    transform: translateY(100%)
  }
`;

const TeamPositionAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%)
  }
  35% {
    opacity: 1;
    transform: translateY(0)
  }
  70% {
    opacity: 1;
    transform: translateY(0)
  }
  100% {
    opacity: 0;
    transform: translateY(100%)
  }
`;

export const MemberWrapper = styled.div`
  position: absolute;
  z-index: 1;
  :first-child {
    top: 35.6%;
    left: 16%;
  }
  :nth-child(2) {
    top: 35.6%;
    right: 15.3%;
  }
  :nth-child(3) {
    top: 63.1%;
    left: 34.9%;
  }
  :nth-child(4) {
    top: 63.1%;
    right: 35.5%;
  }
  ${(props) => props.theme.laptopM`
    :nth-child(3) {
      left: 32%;
    }
    :nth-child(4) {
      right: 27%;
    }
  `}
  ${(props) => props.theme.laptop`
    :nth-child(3) {
      left: 30%;
    }
    :nth-child(4) {
      right: 30%;
    }
  `}
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.tabletL`
    :first-child {
      left: 9%;
      top: 20%;
    }
    :nth-child(2) {
      right: 8.3%;
      top: 20%;
    }
    :nth-child(3) {
      top: 55%;
      left: 22%;
    }
    :nth-child(4) {
      top: 55%;
      right: 21%;
    }
  `}
  @media(max-width: 600px) {
    width: 145px;
    left: unset;
    right: unset;
    top: unset;
    :first-child {
      top: 185px;
      left: 56px;
    }
    :nth-child(2) {
      top: 311px;
      right: 56px;
      left: unset;
    }
    :nth-child(3) {
      top: 449px;
      left: 56px;
    }
    :last-child {
      top: 590px;
      right: 56px;
      left: unset;
    }
  }
  ${(props) => props.theme.mobileL`
  :last-child {
    top: 608px;
    right: 56px;
    left: unset;
  }
  `}
`;

export const MemberImage = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin-bottom: 12px;
  background-size: cover;
  background-position: center 20%;
  background-repeat: no-repeat;
  ${({ src }) =>
    css`
      background-image: url('${src}');
    `}
  ${({ constant }) =>
    !constant &&
    css`
      animation: ${TeamAvatarAnimation} 3s cubic-bezier(0.98, 0.01, 0.18, 0.99)
        0s infinite;
    `}
  ${(props) => props.theme.mobileL`
    width: 100px;
    height: 100px;
  `}
`;

export const MemberDescriptionBox = styled.div`
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`;

export const MemberLabel = styled.h2`
  font-family: Helvetica-nm;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #323232;
  width: max-content;
  ${({ constant, position }) =>
    !constant &&
    (position
      ? css`
          animation: ${TeamPositionAnimation} 3s ease-in-out infinite;
        `
      : css`
          animation: ${TeamNameAnimation} 3s ease-in-out infinite;
        `)}
  ${(props) => props.theme.mobileL`
      font-size: 16px;
      line-height: 1.5;
    `}
`;

export const MemberLabelLight = styled.span`
  font-size: 14px;
  font-weight: normal;
  color: #a1a8b4;
`;
