import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  HeaderContainer,
  Logo,
  HeaderWrapper,
  BurgerMenuIcon,
  LogoWrapper,
  BurgerMenuIconWrapper,
} from './styles';
import { Container } from '../common/styles';
import Navbar from '../nav-bar/Navbar';
import MobileNavBar from '../mobile-nav-bar/MobileNavBar';

const Header = ({
  burgerMenuOpen,
  setBurgerMenuOpen,
  contentVisible,
  setContentVisible,
  setCurrentPosition,
  isMobile,
}) => {
  const history = useHistory();
  const [direction, setDirection] = useState('');
  const [highSpeed, setHighSpeed] = useState(false);

  window.onscroll = function () {
    const scrollDown = this.oldScroll < this.scrollY;
    const scrollUp = this.oldScroll > this.scrollY;
    const height = isMobile ? 70 : 110;

    const highDownSpeed =
      scrollDown && this.scrollY - this.oldScroll > 70 && this.scrollY < 300;
    if (highDownSpeed) {
      setHighSpeed(true);
    } else {
      setHighSpeed(false);
    }

    if (this.scrollY < 1) {
      setDirection('');
      return;
    }
    if (scrollDown && this.scrollY > height) {
      setDirection('down');
    } else if (scrollUp && this.scrollY > 70) {
      setDirection('up');
    }
    this.oldScroll = this.scrollY;
  };

  const handleOpenMenu = () => {
    setBurgerMenuOpen(true);
    setTimeout(() => {
      setContentVisible(true);
    });
  };

  const handleCloseMenu = () => {
    setContentVisible(false);
    setTimeout(() => {
      setBurgerMenuOpen(false);
    }, 600);
  };

  return (
    <HeaderContainer
      direction={direction}
      burgerMenuOpen={burgerMenuOpen}
      highSpeed={highSpeed}
    >
      <Container>
        <HeaderWrapper>
          <LogoWrapper
            onClick={() => {
              if (history.location.pathname !== '/') {
                setCurrentPosition(null);
                history.push('/');
                return;
              }
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }}
          >
            <Logo
              src="/assets/images/wecraft-logo.svg"
              alt="We Craft"
              title="We Craft"
            />
          </LogoWrapper>
          <Navbar setDirection={setDirection} />
          <BurgerMenuIconWrapper
            onClick={() => {
              setCurrentPosition(window.pageYOffset);
              handleOpenMenu();
            }}
          >
            <BurgerMenuIcon
              src={'/assets/icons/burger-menu-icon.svg'}
              alt="Burger Menu"
            />
          </BurgerMenuIconWrapper>
          <MobileNavBar
            open={burgerMenuOpen}
            setOpen={handleCloseMenu}
            contentVisible={contentVisible}
            setDirection={setDirection}
            setCurrentPosition={setCurrentPosition}
          />
        </HeaderWrapper>
      </Container>
    </HeaderContainer>
  );
};

export default Header;
