import styled from 'styled-components';

export const BannerContainer = styled.section`
  width: 100%;
  height: 100vh;
  max-height: 1100px;
  min-height: 800px;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  background-image: url('/assets/images/Oval.svg');
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 20.5%;
  position: relative;
  overflow-x: hidden;
  /* pointer-events: none; */
  ${(props) => props.theme.tabletL`
    height: 100vh;
    min-height: 550px;
    background-image: none;
    overflow-y: hidden;
    url('/assets/images/mobile-banner-oval.svg');
    background-size: initial;
    background-position: calc(100% + 225px) -33%;
    @media all and (orientation: landscape) {
      min-height: 800px;
    }
  `}
  ${(props) => props.theme.mobileM`
     background-position: calc(100% + 225px) -33%;
  `}
`;

export const MobileOvalWrapper = styled.div`
  position: absolute;
  display: none;

  ${(props) => props.theme.tabletL`
    display: block;
    width: 84%;
    top: -56%;
    right: -53%;
  `}
  ${(props) => props.theme.mobileL`
  width: 93%;
  top: -16%;
  right: -53%;
  `}
`;

export const MobileOvalImg = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

export const BannerWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  ${(props) => props.theme.tabletL`
    justify-content: flex-start;
  `}
`;

export const BannerDescWrapper = styled.div`
  max-width: 581px;
  z-index: 1;
  ${(props) => props.theme.laptopL`
    max-width: 510px;
  `}
  ${(props) => props.theme.tabletL`
    width: 100%;
    margin-top: 166px;
    max-width: unset;
  `}
  @media (max-height: 750px) {
    margin-top: 130px;
  }
  /* @media (max-height: 750px) {
    margin-top: 80px;
  } */
`;

export const BannerCaption = styled.h2`
  font-family: Eina-r;
  font-size: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -1.88px;
  color: #323232;
  margin-bottom: 6px;
  ${(props) => props.theme.laptopL`
    font-size: 45px;
    line-height: 1.2;
  `}
  ${(props) => props.theme.tabletL`
    text-align: center;
    font-size: 50px;
    line-height: 1.29;
    letter-spacing: -1.16px;
  `}
  ${(props) => props.theme.mobileL`
    font-size: 31px;
  `}
  ${(props) => props.theme.mobileS`
    font-size: 26px;
  `}
`;

export const MainCaption = styled.span`
  font-family: Eina-sb;
`;

export const BannerDescription = styled.p`
  font-family: Helvetica-n;
  opacity: 0.4;
  padding-left: 7px;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: 0.3px;
  color: #000000;
  width: 100%;
  margin-bottom: 37px;
  ${(props) => props.theme.laptopL`
    font-size: 14px;
    line-height: 1.6;
  `}
  ${(props) => props.theme.tabletL`
    font-size: 16px;
    line-height: 1.83;
    text-align: center;
    color: #9fa4b8;
    opacity: 1;
  `}
  ${(props) => props.theme.mobileL`
    font-size: 12px;
  `}
`;

export const ButtonWrapper = styled.div`
  width: 201px;
  height: 58px;
  margin-top: 100px;
  @media (max-height: 700px) {
    bottom: 20px;
  }
  ${(props) => props.theme.tabletL`
    width: 306px;
    height: 56px;
    position: absolute;
    bottom: 39px;
    margin: 0 auto;
    left: calc((100% - 306px)/2);
    z-index: 3;
  `}
`;

export const VideoWrapper = styled.div`
  position: absolute;
  width: 77%;
  bottom: 0;
  right: -144px;
  right: -9.2%;
  pointer-events: none;
  user-select: none;

  ${(props) => props.theme.tabletL`
    z-index: 0;
    height: 510px;
    width: 100%;
    left: -3%;
  `}
  ${(props) => props.theme.mobileL`
   height: 380px;
    width: 168%;
    left: -18%;
    bottom: 11.2%;
  `}
  ${(props) => props.theme.mobileM`
    height: 369px;
    left: -30%;
  `}
  ${(props) => props.theme.mobileS`
    height: 318px;
    left: -33%;
    bottom: 130px;
  `}

  @media (max-height: 700px) {
    bottom: 6.2%;
  }
  @media (max-height: 650px) {
    bottom: 0;
  }
`;

export const BannerVideo = styled.video`
  width: 100%;
  height: auto;
  ${(props) => props.theme.tabletL`
  height: 100%;
  width: auto;
  `}
`;

export const MobileGradientLayer = styled.div`
  width: 335px;
  height: 400px;
  border-style: solid;
  border-width: 42.2px;
  border-image-source: linear-gradient(175deg, #dadff1 3%, #ebeefb 64%);
  border-image-slice: 1;
  bottom: 25px;
  left: 0;
  position: absolute;
  display: none;
  ${(props) => props.theme.tabletL`
    display: block;
  `}
`;

export const GradientLayer = styled.div`
  position: absolute;
  bottom: 0;
  height: 29%;
  width: 85%;
  background-image: linear-gradient(to bottom, rgba(229, 232, 240, 0), #ebeefb);
  z-index: 1;
  left: 7.5%;
  ${(props) => props.theme.tabletL`
    width: 100%;
  `}
`;

export const VideoPoster = styled.img`
  user-select: none;
  pointer-events: none;
  height: 100%;
  width: auto;
`;

export const Emoji = styled.span`
  font-size: 38px;
  margin-left: 10px;
`;
