export const services = [
  {
    title: 'Android Development',
    service: 'mobile-development',
    id: 1,
  },
  {
    title: 'iOS Development',
    service: 'mobile-development',
    id: 2,
  },
  {
    title: 'Cloud Migration',
    service: 'cloud-development',
    id: 3,
  },
  {
    title: 'Cloud Discovery and Optimization',
    service: 'cloud-development',
    id: 4,
  },
  {
    title: 'E-commerce Development',
    service: 'web-development',
    id: 5,
  },
  {
    title: 'CMS Development',
    service: 'web-development',
    id: 6,
  },
  {
    title: 'Higher Code Quality',
    service: 'team-optimization',
    id: 7,
  },
  {
    title: 'Increased Test Coverage',
    service: 'team-optimization',
    id: 8,
  },
];

export const budgets = [
  {
    title: 'Less than 5K',
    id: 1,
  },
  {
    title: '5K - 10K',
    id: 2,
  },
  {
    title: '10K - 30K',
    id: 3,
  },
  {
    title: 'More than 30K',
    id: 4,
  },
];
