import { css } from 'styled-components';

const screenSizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tabletS: 640,
  tabletM: 726,
  tablet: 768,
  tabletL: 940,
  laptop: 1024,
  laptopM: 1224,
  laptopL: 1440,
  desktop: 2560,
};

export default Object.keys(screenSizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${screenSizes[label]}px) {
      //@ts-ignore
      ${css(...args)}
    }
  `;
  return acc;
}, {});
