import React, { useRef, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { JobListWrapper, JobsWrapper } from '../job-list/styles';
import { innerJobs } from './innerJobs';
import {
  InnerJobWrapper,
  JobDate,
  JobTitle,
  JobHeading,
  SectionTitle,
  Description,
  SectionWrapper,
  List,
  ListItem,
  SimilarJobsWrapper,
  SimilarJobsTitle,
  HeadingRow,
  MobileJobHeading,
  Line,
  ActionsNav,
  BackButton,
  BackArrow,
  BackLabel,
} from './styles';
import { jobs } from '../job-list/jobs';
import Job from '../../components/job/Job';
import Location from '../../components/location/Location';
import { LocationsWrapper } from '../../components/job/styles';
import { MobileContentShadow } from '../home/styles';
import { scrollToCurrentPosition } from '../../utils/helpers';

const InnerJob = ({ contentVisible, currentPosition, setCurrentPosition }) => {
  const match = useRouteMatch();
  const history = useHistory();

  const contentRef = useRef(null);
  useEffect(() => {
    scrollToCurrentPosition(contentRef, currentPosition, contentVisible);
  }, [currentPosition, contentVisible]);

  const isMobile = useMediaQuery({
    query: '(max-width: 425px)',
  });
  const { title } = match.params;
  const currentJob = innerJobs.find((item) => item.param === title);

  return (
    <>
      <MobileContentShadow ref={contentRef} visible={contentVisible} />
      <JobListWrapper inner="true" contentVisible={contentVisible}>
        {currentJob && (
          <InnerJobWrapper>
            {isMobile ? (
              <>
                <ActionsNav>
                  <BackButton onClick={() => history.push('/jobs')}>
                    <BackArrow src="/assets/icons/back-icon.svg" alt="arrow" />
                    <BackLabel>Back</BackLabel>
                  </BackButton>
                </ActionsNav>
                <MobileJobHeading>
                  <LocationsWrapper>
                    {currentJob.locations.map((l, i) => {
                      return <Location key={i} location={l} />;
                    })}
                  </LocationsWrapper>
                  <JobTitle>{currentJob.title}</JobTitle>
                  <JobDate>{currentJob.date}</JobDate>
                  <Line />
                </MobileJobHeading>
              </>
            ) : (
              <JobHeading>
                <JobDate>{currentJob.date}</JobDate>
                <HeadingRow>
                  <JobTitle>{currentJob.title}</JobTitle>
                  {currentJob.locations.length && (
                    <LocationsWrapper>
                      {currentJob.locations.map((l, i) => {
                        return <Location key={i} location={l} />;
                      })}
                    </LocationsWrapper>
                  )}
                </HeadingRow>
                <Line />
              </JobHeading>
            )}
            <SectionWrapper>
              <SectionTitle>What we're looking for</SectionTitle>
              <Description>{currentJob.brief}</Description>
            </SectionWrapper>
            {currentJob.responsibilities.length && (
              <SectionWrapper>
                <SectionTitle>Responsibilities</SectionTitle>
                <List>
                  {currentJob.responsibilities.map((r) => {
                    return <ListItem key={r}>- {r}</ListItem>;
                  })}
                </List>
              </SectionWrapper>
            )}
            {currentJob.qualifications.length && (
              <SectionWrapper>
                <SectionTitle>Qualifications</SectionTitle>
                <List>
                  {currentJob.qualifications.map((q) => {
                    return <ListItem key={q}>- {q}</ListItem>;
                  })}
                </List>
              </SectionWrapper>
            )}
            {currentJob.benefits.length && (
              <SectionWrapper>
                <SectionTitle>Benefits</SectionTitle>
                <List>
                  {currentJob.benefits.map((b) => {
                    return <ListItem key={b}>- {b}</ListItem>;
                  })}
                </List>
              </SectionWrapper>
            )}
            <SectionWrapper>
              <SectionTitle>About Us</SectionTitle>
              <Description>{currentJob.about}</Description>
            </SectionWrapper>
            <SectionWrapper>
              <SectionTitle>How to Apply</SectionTitle>
              <Description>{currentJob.howToApply}</Description>
            </SectionWrapper>
            {!isMobile && (
              <SimilarJobsWrapper>
                <SimilarJobsTitle>Similar Jobs</SimilarJobsTitle>
                <JobsWrapper>
                  {jobs
                    .filter((j) => j.redirectParam !== title)
                    .map((item) => {
                      return <Job key={item.title} job={item} />;
                    })}
                </JobsWrapper>
              </SimilarJobsWrapper>
            )}
          </InnerJobWrapper>
        )}
      </JobListWrapper>
    </>
  );
};

export default InnerJob;
