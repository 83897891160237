import styled, { keyframes, css } from 'styled-components';

export const TimelineContainer = styled.section`
  width: auto;
  /* padding-top: 105px; */
  padding-top: 5.5%;
  /* margin-bottom: 50px; */
  height: 100vh;
  min-height: 800px;
  max-height: 1100px;
  @media (max-height: 850px) {
    padding-top: 2%;
  }
  ${(props) => props.theme.tabletL`
    min-height: 850px;
    overflow-y: hidden;
    background-color: #ffffff;
    z-index: 3;
    padding-top: 30px;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    ::-webkit-scrollbar {
    display: none;
    }
  `}
`;

export const TimelineInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  width: 81%;
  max-width: 1552px;
  margin: 0 auto;
  ${(props) => props.theme.tabletL`
    width: auto;
    position: relative;
  `}
`;

export const TimelineWrapper = styled.div`
  padding: 0 5.4% 0 4.8%;
  ${(props) => props.theme.tabletL`
    padding: 0;
    position: relative;
  `}
`;

export const Caption = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme.tabletL`
    margin-left: 24px;
  `}
`;

export const StartIconWrapper = styled.div`
  margin-right: 8px;
  z-index: 1;
`;

export const EmojiIcon = styled.img`
  width: 74px;
  border-radius: 50%;
  object-fit: contain;
`;

export const TimelineContent = styled.div`
  width: 100%;
  padding-left: 36px;
  padding-right: 28px;
  position: relative;
  ${(props) => props.theme.tabletL`
    width: unset;
    padding-left: unset;
    padding-right: unset;
  `}
`;

export const LinesList = styled.div`
  position: absolute;
  /* top: -109px; */
  top: -20.5%;
  width: calc(100% - 64px);
  display: flex;
  justify-content: space-between;
  z-index: -1;
  /* height: 704px; */
  height: 132%;
  ${(props) => props.theme.tabletL`
    z-index: 0;
    width: 660px;
    margin-left: 60px;
    top: -11.5%;
  `}
`;

export const Line = styled.div`
  width: 1px;
  /* height: 604px; */
  height: 86%;
  /* margin-top: 109px; */
  margin-top: 8.53%;
  background-color: #eaedf9;
  :first-child {
    margin-top: 0;
    /* height: 704px; */
    height: 100%;
  }
  :nth-child(7) {
    /* height: 695px; */
    height: 98.7%;
  }
  @media (max-height: 950px) {
    :nth-child(7) {
      /* height: 695px; */
      height: 88.7%;
    }
  }
`;

export const LinesLayer = styled.div`
  width: 84%;
  /* height: 234px; */
  height: 34%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
  position: absolute;
  /* bottom: 75px; */
  /* bottom: 9.3%; */
  bottom: -2.7%;
`;

export const StepsList = styled.ul`
  /* margin-top: 65px; */
  margin-top: 5%;
  ${(props) => props.theme.tabletL`
    z-index: 2;
    position: relative;
    margin-top: 35px;
  `}
`;

export const DragButtonShrinkAnimation = keyframes`
   from {
    width: 326px;
  }
  to {
    width: 73px;
  }
`;

export const DragButtonExpandAnimation = keyframes`
  from {
    width: 73px;
  }
  to {
    width: 326px;
  }
`;

export const DragButtonContainer = styled.div`
  width: 765px;
  position: absolute;
  left: 0;
  bottom: -200px;
`;

export const DragButtonWrapper = styled.div`
  /* width: 326px; */
  width: 73px;
  height: 70px;
  border-radius: 36px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding-left: 15px;
  display: none;
  align-items: center;
  left: 24px;
  transition: all 0.5s ease-in-out;
  @media (max-width: 740px) {
    display: flex;
  }
  position: sticky;
  ${({ visible }) => visible && `width: 340px`}/* ${({ scrollDirection }) => {
    if (scrollDirection === 'right') {
      return css`
        width: 326px;
        animation: ${DragButtonExpandAnimation} 1s;
      `;
    }
    if (scrollDirection === 'left') {
      return css`
        width: 73px;
        animation: ${DragButtonShrinkAnimation} 1s;
      `;
    }
  }} */
`;

export const DragIcon = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 16px;
`;

export const DragLabel = styled.span`
  font-family: Helvetica-n;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.14px;
  color: #c3c3c3;
  opacity: 0;
  flex: 1;
  overflow-x: hidden;
  white-space: nowrap;
  ${({ visible }) =>
    visible &&
    `
    opacity: 1;
  `}
  transition: all 0.5s ease-in-out;
`;

export const EmojiEndWrapper = styled.div`
  position: absolute;
  right: -8px;
  bottom: -34%;
  z-index: 2;

  @media (max-height: 950px) {
    bottom: -26%;
  }
  ${(props) => props.theme.tabletL`
      bottom: -20%;
      left: 682px;
      padding-right: 105px;
  `}
`;
