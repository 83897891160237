import media from './media';

const theme = {
  colors: {
    primary: '#624de3',
    mainBackground: '#edeefa',
  },
  ...media,
};

export default theme;
