import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import {
  ProjectCardWrapper,
  Background,
  ProjectAnimationWrapper,
} from './styles';

const ProjectCard = ({
  backgroundImg,
  hasMargin,
  name,
  redirectUrl,
  children,
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <VisibilitySensor
      partialVisibility={true}
      minTopValue={100}
      onChange={(isVisible) => {
        if (isVisible && !visible) {
          setVisible(true);
        }
      }}
    >
      <ProjectAnimationWrapper visible={visible}>
        <ProjectCardWrapper
          backgroundImg={backgroundImg}
          left
          onClick={() => {
            if (!redirectUrl) return;
            window.open(redirectUrl);
          }}
          hasMargin={hasMargin}
        >
          <Background src={backgroundImg} alt={name} />
          {children}
        </ProjectCardWrapper>
      </ProjectAnimationWrapper>
    </VisibilitySensor>
  );
};

export default ProjectCard;
