import styled, { css, keyframes } from 'styled-components';

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100vh;
  /* background-color: #e9ecfa; */
  background-color: #eeeefb;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 200;
  left: 0;
  top: 0;
  transition: top 1.5s ease-out;
  /* background-image: ; */
  background-image: url('/assets/images/jobs-oval-right.svg'),
    url('/assets/images/jobs-oval-bottom.svg');
  background-position: top right, bottom left;
  background-repeat: no-repeat;
  background-size: 25%, 30%;
  ${({ isLoading }) =>
    !isLoading &&
    css`
      top: 200%;
    `}
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ isLoading }) =>
    isLoading &&
    css`
      display: none;
    `}
  ${(props) => props.theme.tabletL`
    flex-direction: column;
  `}
`;

export const Label = styled.span`
  color: #624de3;
  font-family: Eina-b;
  font-size: 40px;
  margin-right: 20px;
  ${(props) => props.theme.tabletL`
    margin-bottom: 30px;
  `}
  ${(props) => props.theme.mobileL`
    font-size: 30px;
    margin-right: unset;
    margin-bottom: 10px;
  `}
`;

export const LabelAnimation = keyframes`
    0% { margin-top: -270px; }
    5% { margin-top: -180px; }
    30% { margin-top: -180px; }
    33.3% { margin-top: -89px; }
    63% { margin-top: -89px }
    66.6% { margin-top: 0px; }
    99.99% { margin-top: 0px; }
    100% { margin-top: -180px; }
`;

export const SubLabelsContainer = styled.div`
  position: relative;
  animation: ${LabelAnimation} 6s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
`;

export const SubLabelWrapper = styled.div`
  overflow-y: hidden;
  height: 60px;
  margin-top: 9px;
`;

export const SubLabel = styled.span`
  font-size: 40px;
  font-family: Eina-r;
  color: #323232;
  display: block;
  white-space: nowrap;
  height: 43px;
  margin-bottom: 48px;
  ${(props) => props.theme.tabletL`
    text-align: center;
  `}
  ${(props) => props.theme.mobileL`
    font-size: 25px;
    text-align: center;
  `}
  ${(props) => props.theme.mobileM`
    font-size: 23px;
  `}
  ${(props) => props.theme.mobileL`
    font-size: 19px;
  `}
`;
