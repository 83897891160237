import styled from 'styled-components';

export const TeamContainer = styled.section`
  width: 100%;
  height: 100vh;
  min-height: 800px;
  max-height: 1100px;
  background-image: linear-gradient(to bottom, #f5f7ff, #ffffff 58%);
  display: flex;
  justify-content: center;
  position: relative;
  ${(props) => props.theme.mobileL`
    padding: 0 28px;
    min-height: 812px;
  `}
`;

export const TeamBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
  ${({ visible }) =>
    visible &&
    `opacity: 1;
    `}
  ${(props) => props.theme.tabletL`
      object-fit: cover;
    `}
  ${(props) => props.theme.mobileL`
    display: none;
  `}
`;

export const SectionHeading = styled.div`
  margin: 0 auto;
  z-index: 1;
  /* margin-top: 80px; */
  margin-top: 3.8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.mobileL`
    margin-top: 50px;
  `}
`;

export const TeamSectionTitle = styled.h2`
  font-family: Eina-b;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #624de3;
  margin-bottom: 8px;
  ${(props) => props.theme.mobileL`
    font-size: 28px;
    letter-spacing: -1.05px;
    margin-bottom: 12px;
  `}
`;

export const TeamSectionSubTitle = styled.p`
  max-width: 537px;
  opacity: 0.6;
  font-family: Helvetica-nm;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: center;
  color: #323232;
  ${(props) => props.theme.mobileL`
    font-size: 14px;
    line-height: 1.71;
    white-space: pre-line;
  `}
`;

export const Dot = styled.div`
  display: none;
  position: absolute;
  border-radius: 50%;
  ${(props) => props.theme.mobileL`
    display: block;
  `}

  ${({ gray }) =>
    gray &&
    `
    width: 48px;
    height: 48px;
    top: 174px;
    right: 90px;
    opacity: 0.5;
    background-image: linear-gradient(to bottom, #bbbfc6, #ffffff);
  `}
  ${({ green }) =>
    green &&
    `width: 61px;
    height: 61px;
    top: 362px;
    left: 116px;
    opacity: 0.5;
    background-image: linear-gradient(to bottom, #a2cfae, #ffffff);`}
  ${({
    pink,
  }) =>
    pink &&
    `
     width: 67px;
    height: 67px;
    top: 604px;
    right: 37px;
    opacity: 0.3;
    background-image: linear-gradient(to top, #ffffff, #cf838e 53%);
  `}
`;

export const MembersWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const MembersContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
