import styled from 'styled-components';

export const InnerJobWrapper = styled.div`
  padding-top: 199px;
  padding-bottom: 239px;
  width: 47.3%;
  margin: 0 auto;
  ${(props) => props.theme.tabletL`
    width: 81%;
    padding-top: 130px;
    padding-bottom: 70px;
  `}
  ${(props) => props.theme.mobileL`
    width: 87%;
    padding-top: 80px;
  `}
`;

export const JobHeading = styled.div`
  margin-bottom: 55px;
`;

export const JobDate = styled.span`
  font-family: Helvetica-n;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8e8e96;
  margin-bottom: 5px;
  display: block;
  ${(props) => props.theme.mobileL`
    margin-bottom: 19px;
    padding-left: 1px;
  `}
`;

export const HeadingRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const JobTitle = styled.h2`
  font-family: Eina-b;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.13px;
  color: #323232;
  margin-bottom: 14px;
  ${(props) => props.theme.mobileL`
  font-size: 22px;
  letter-spacing: -0.83px;
  margin-bottom: 3px;
  `}
`;

export const SectionTitle = styled.h4`
  font-family: Eina-sb;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  color: #323232;
  margin-bottom: 13px;
  ${(props) => props.theme.mobileL`
    font-size: 20px;
    letter-spacing: -0.75px;
    margin-bottom: 4px;
  `}
`;

export const Description = styled.p`
  font-family: Helvetica-n;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #8e8e96;
  width: 100%;
  white-space: pre-line;
`;

export const SectionWrapper = styled.div`
  margin-bottom: 37px;
  ${(props) => props.theme.mobileL`
    margin-bottom: 43px;
  `}
`;

export const List = styled.ul``;

export const ListItem = styled.li`
  font-family: Helvetica-n;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  color: #8e8e96;
`;

export const SimilarJobsWrapper = styled.div`
  margin-top: 111px;
`;

export const SimilarJobsTitle = styled.h2`
  margin-left: 16px;
  margin-bottom: 20px;
  font-family: Eina-b;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  color: #323232;
`;

export const MobileJobHeading = styled.div`
  margin-bottom: 39px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dfdfee;
`;

export const ActionsNav = styled.div`
  border-bottom: 1px solid #dfdfee;
  margin-bottom: 20px;
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const BackArrow = styled.img`
  width: 24px;
  height: 24px;
  margin-left: -5px;
  margin-right: 1px;
`;

export const BackLabel = styled.h3`
  font-family: Helvetica-nm;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #323232;
`;
