import React from 'react';
import { LocationContainer, LocationLabel } from './styles';

const Location = ({ location }) => {
  const { label, color } = location;
  return (
    <LocationContainer color={color}>
      <LocationLabel>{label}</LocationLabel>
    </LocationContainer>
  );
};

export default Location;
