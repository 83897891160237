import styled from 'styled-components';

export const JobListWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #eeeefb;
  background-image: url('/assets/images/inner-job-left.svg'),
    url('/assets/images/jobs-oval-right.svg');
  background-repeat: no-repeat;
  /* background-position: 0 192%, 100% 37%; */
  background-position: 0 60vh, 100% 23vh;
  background-size: 20.5%, 15.5%;
  transition: left 0.6s cubic-bezier(0.23, 1, 0.32, 1.2);
  left: calc(-100% - 35px);
  ${({ inner }) =>
    inner &&
    `
    height: fit-content;
    min-height: 100vh;
    overflow: unset;
    `}
  /* background-position: 0 616px, 100% 188px; */ 
  ${(props) => props.theme
    .tabletL`
    background-image: unset;
    background-repeat: unset;
    background-size: unset;
    background-position: unset;
    ${
      props.contentVisible &&
      `
      min-height: unset;
    transform: scale(0.8);
    overflow-y: auto;
    position: absolute;
    top: 49px;
    left: -61%;
    width: 100%;
    height: 90vh;
    border-radius: 20px;
    z-index: 90;
    pointer-events: none;
    ::-webkit-scrollbar {
    display: none;
    }`
    }
  `}
    ${(props) => props.theme.mobileL`
    ${
      props.contentVisible &&
      `
      height: 85vh;
    `
    }
  `}
`;

export const JobListContainer = styled.div`
  max-width: 908px;
  margin: 0 auto;
  padding-top: 13.54%;
  padding-bottom: 20px;
  ${(props) => props.theme.tabletL`
    width: 81%;
    padding-top: 22%;
  `}
  ${(props) => props.theme.mobileL`
    padding-top: 45.1%;
    width: 91.5%;
  `}
`;

export const JobListHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 51px;
  ${(props) => props.theme.tabletL`
    margin-bottom: 41px;
  `}
`;

export const Caption = styled.h2`
  font-family: Eina-r;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -1.13px;
  text-align: center;
  color: #323232;
  white-space: pre-line;
  margin-bottom: 8px;
  ${(props) => props.theme.tabletL`
    display: none;
  `}
`;

export const SubCaption = styled.p`
  font-family: Helvetica-n;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: normal;
  text-align: center;
  color: #8e8e96;
  white-space: pre-line;
  max-width: 685px;
  ${(props) => props.theme.tabletL`
    font-size: 12px;
    line-height: 1.83;
    color: #9fa4b8;
  `}
`;
export const JobsWrapper = styled.div``;

export const MobileCaption = styled.h2`
  display: none;
  font-family: Eina-r;
  font-size: 31px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -1.16px;
  text-align: center;
  color: #323232;
  margin-bottom: 11px;
  ${(props) => props.theme.tabletL`
    display: block;
  `}
`;
