import styled from 'styled-components';

export const HeaderContainer = styled.header`
  position: absolute;
  width: 100%;
  z-index: 10;
  height: 110px;
  transition: 0.8s ease-in-out;
  ${({ direction, burgerMenuOpen, highSpeed }) => {
    if (!burgerMenuOpen) {
      if (!direction) {
        return `
        background: transparent;
        top: 0;
      `;
      }
      if (direction === 'down') {
        // transition: 0.8s ease-in;
        return `
        top: -110px;
        ${highSpeed ? 'transition: unset;' : ''}
        ${window.scrollY > 200 ? `position: fixed` : ''}
      `;
      } else if (direction === 'up') {
        // transition: 0.8s ease-out;
        return `
        position: fixed;
        top: 0;
        background-color: #ffffff;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
      `;
      }
    }
  }};
  ${(props) => props.theme.mobileL`
    height: 70px;
  `}
`;

export const Logo = styled.img`
  width: 87px;
  object-fit: contain;
  ${(props) => props.theme.mobileL`
     width: 54px;
  `}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const MobileMenuWrapper = styled.div`
  display: none;
  ${(props) => props.theme.mobileL`
    display: block;
  `}
`;

export const BurgerMenuIcon = styled.img`
  cursor: pointer;
  width: 22px;
  object-fit: cover;
`;

export const LogoWrapper = styled.div`
  cursor: pointer;
  ${(props) => props.theme.mobileL`
    cursor: unset;
  `}
`;

export const BurgerMenuIconWrapper = styled.div`
  padding: 10px;
  margin-right: -10px;
  display: none;
  ${(props) => props.theme.tablet`
    display: block;
  `}
`;
