import styled from 'styled-components';

const toolTipTextColor = 'white';
const toolTipBackgroundColor = '#000000';
const toolTipMargin = '30px';
const toolTipArrowSize = '6px';

export const ToolTipWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

export const ToolTipTip = styled.div`
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: ${toolTipTextColor};
  background: ${toolTipBackgroundColor};
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
  &:before{
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: ${toolTipArrowSize};
    margin-left: calc(${toolTipArrowSize} * -1);
  }
  &.top{
    top: calc(${toolTipMargin} * -1);
    &:before{
        top: 100%;
        border-top-color: ${toolTipBackgroundColor};
    }
  }

  &.right{
    left: calc(100% + ${toolTipMargin});
    top: 50%;
    transform: translateX(0) translateY(-50%);
    &:before{
        left: calc(${toolTipArrowSize} * -1);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-right-color: ${toolTipBackgroundColor});
    }
  }

  &.bottom{
    bottom: calc(${toolTipMargin} * -1);
    &:before{
        bottom: 100%;
        border-bottom-color: ${toolTipBackgroundColor});
    }
  }

  &.left{
    left: auto;
    right: calc(100% + ${toolTipMargin});
    top: 50%;
    transform: translateX(0) translateY(-50%);
    &:before{
        left: auto;
        right: calc(${toolTipArrowSize} * -2);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-left-color: ${toolTipBackgroundColor});
    }
  }
`;
