import React from 'react';
import { List, ListItem } from '../timeline-step-description/styles';

export const steps = [
  {
    label: 'Handshake',
    description: (
      <List>
        <ListItem>
          The service will be handled by the co-founder from end-to-end … with
          no delegation of essential tasks
        </ListItem>
        <ListItem>
          In-person world-wide meeting within 72 hours ... from first contact
        </ListItem>
        <ListItem>
          We are the co-founder lead team of 25 quality oriented experts
        </ListItem>
        <ListItem>
          We adhere to strict confidentiality procedures … and can provide full
          legal documentation in line with industry standards (such as NDA,
          etc.) based on your specifications
        </ListItem>
      </List>
    ),
    position: '0',
    // width: '292px',
    width: '22%',
    minWidth: '140px',
    mobileWidth: '251px',
    mobilePosition: '0px',
  },
  {
    label: 'Scope Definition',
    description: (
      <List>
        <ListItem>
          Detailed documentations of project definition and scope with concrete
          action plan after kickoff meeting with no extra follow ups or costs
        </ListItem>
        <ListItem>Detailed price breakdown with no hidden costs</ListItem>
        <ListItem>
          Whole projects consists of a clear step-by-step breakdown and schedule
          of deliverables
        </ListItem>
        <ListItem>
          Clear prioritisation of tasks and deliverables to achieve maximum
          efficiency
        </ListItem>
      </List>
    ),
    // position: '160px',
    position: '12%',
    // width: '470px',
    width: '35.3%',
    minWidth: '161px',
    mobileWidth: '300px',
    mobilePosition: '78px',
  },
  {
    label: 'Timeline & Pricing',
    description: (
      <List>
        <ListItem>
          Flexibility of scaling the team and decreasing time-to-delivery based
          on urgency of the project
        </ListItem>
        <ListItem>
          All international transactions are accepted (please, no blood
          diamonds)
        </ListItem>
        <ListItem>Payments based upon scheduled milestones</ListItem>
        <ListItem>
          Competitive price guaranteed in line with market value of delivered
          quality
        </ListItem>
        <ListItem>
          All potential delays are taken into account during planning phase
        </ListItem>
        <ListItem>
          Any expenses due to extra delays unrelated to expansion of initial
          scope are covered by us
        </ListItem>
      </List>
    ),
    // position: '344px',
    position: '26%',
    // width: '434px',
    width: '32.6%',
    minWidth: '230px',
    mobileWidth: '310px',
    mobilePosition: '211px',
  },
  {
    label: 'Team definition',
    description: `To maximize output quality and efficiency, team setup is customized based on project specifications and can include: Project Manager, Reporting Manager, Lead Engineer, Front and Backend Engineers, QA team, UX/UI Designer, Graphic Designer, Content/SEO Manager
    `,
    // position: '399px',
    position: '30%',
    // width: '231px',
    width: '17.4%',
    minWidth: '168px',
    mobileWidth: '210px',
    mobilePosition: '129px',
  },
  {
    label: 'Full visibility of build process',
    description: (
      <List>
        <ListItem>
          Full transparency of the plan details and step-by-step work progress …
          using cutting edge agile technology (Clubhouse, Trello, Jira, Notion,
          Monday etc.)
        </ListItem>
        <ListItem>
          Daily progress reporting, weekly deployments and demos
        </ListItem>
        <ListItem>
          Holistic project progress tracking (e.g. 23% complete)
        </ListItem>
      </List>
    ),
    // position: '665px',
    position: '50%',
    // width: '584px',
    width: '44%',
    minWidth: '270px',
    mobileWidth: '350px',
    mobilePosition: '289px',
  },
  {
    label: 'Infrastructure Setup',
    description: `Quick cloud solution, communication setup, file sharing and storage, design setup, password and user system management setup
    `,
    // position: '751px',
    position: '56.5%',
    // width: '300px',
    width: '22.5%',
    minWidth: '205px',
    mobileWidth: '250px',
    mobilePosition: '320px',
  },
  {
    label: 'Project Handover',
    description: (
      <List>
        <ListItem>Lear documentation</ListItem>
        <ListItem>Access handover</ListItem>
        <ListItem>IP handover ( codebase, design )</ListItem>
        <ListItem>
          In-house team setup or continuous maintenance and security governance
        </ListItem>
      </List>
    ),
    // position: '920px',
    position: '69.2%',
    // width: '409px',
    width: '30.7%',
    minWidth: '245px',
    mobileWidth: '310px',
    mobilePosition: '350px',
  },
];
