import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import {
  ServiceContainer,
  ServiceWrapper,
  ServiceContent,
  DotsNav,
  Dot,
  ServiceHeading,
  Caption,
  SubCaption,
  ServiceCardsNav,
  ServicesBackground,
} from './styles';
import ServiceCard from '../service-card/ServiceCard';
import { serviceList, innerServices } from './service-list';
import ServiceInnerCard from '../service-inner-card.js/ServiceInnerCard';
import { DesktopWrapper } from '../projects/styles';
import { AnimationWrapper } from '../common/styles';

const Services = ({ setChosenService, setSelectedServices }) => {
  const [active, setActive] = useState();

  const [visible, setVisible] = useState({
    caption: false,
    subCaption: false,
  });
  const [isTablet, setTablet] = useState(false);

  const onResize = () => {
    const windowWidth = window.innerWidth
      ? window.innerWidth
      : window.screen.width;
      
    if (windowWidth <= 940) {
      setActive(0);
      setTablet(true);
    } else {
      setActive(null);
      setTablet(false);
    }
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);



  return (
    <ServiceContainer id="service">
      <ServicesBackground src={'/assets/images/hand.svg'} alt="hand" />
      <ServiceWrapper>
        <ServiceContent>
          <ServiceInnerCard
            show={typeof active === 'number'}
            active={active}
            setActive={setActive}
            innerServices={isTablet ? serviceList : innerServices}
            setChosenService={setChosenService}
            setSelectedServices={setSelectedServices}
          />
          <DotsNav>
            <Dot red />
            <Dot green />
            <Dot />
          </DotsNav>
          <DesktopWrapper>
            <ServiceHeading>
              <VisibilitySensor
                onChange={(isVisible) => {
                  if (isVisible && !visible.caption) {
                    setVisible((curr) => ({ ...curr, caption: true }));
                  }
                }}
              >
                <AnimationWrapper visible={visible.caption}>
                  <Caption>Our Services</Caption>
                </AnimationWrapper>
              </VisibilitySensor>
              <VisibilitySensor
                onChange={(isVisible) => {
                  if (isVisible && !visible.subCaption) {
                    setVisible((curr) => ({ ...curr, subCaption: true }));
                  }
                }}
              >
                <AnimationWrapper
                  visible={visible.subCaption}
                  customOpacity={0.76}
                >
                  <SubCaption>Learn how we craft your experience</SubCaption>
                </AnimationWrapper>
              </VisibilitySensor>
            </ServiceHeading>
            <ServiceCardsNav>
              {serviceList.map((s, i) => {
                return (
                  <ServiceCard
                    key={s.name}
                    data={s}
                    clickHandler={() => setActive(i)}
                  />
                );
              })}
            </ServiceCardsNav>
          </DesktopWrapper>
        </ServiceContent>
      </ServiceWrapper>
    </ServiceContainer>
  );
};

export default Services;
