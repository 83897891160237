import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import {
  ProjectContainer,
  ProjectWrapper,
  Column,
  ProjectSectionHeading,
  DesktopWrapper,
  MobileWrapper,
  SectionDescription,
} from './styles';
import { Container, AnimationWrapper } from '../common/styles';
import ProjectCard from '../project-card/ProjectCard';
import PageCaption from '../common/PageCaption';
import {
  ProjectHeading,
  ProjectDescription,
  ProjectIcon,
  BoxNav,
  ProjectTag,
  ProjectTitle,
} from '../project-card/styles';

const Projects = () => {
  const [visible, setVisible] = useState({
    caption: false,
    subCaption: false,
  });

  return (
    <ProjectContainer id="projects">
      <Container>
        <ProjectWrapper>
          <Column left>
            <ProjectSectionHeading>
              <VisibilitySensor
                onChange={(isVisible) => {
                  if (isVisible && !visible.caption) {
                    setVisible((curr) => ({ ...curr, caption: true }));
                  }
                }}
              >
                <AnimationWrapper visible={visible.caption}>
                  <PageCaption label="Our projects" />
                </AnimationWrapper>
              </VisibilitySensor>
              <VisibilitySensor
                onChange={(isVisible) => {
                  if (isVisible && visible.caption) {
                    setVisible((curr) => ({ ...curr, subCaption: true }));
                  }
                }}
              >
                <AnimationWrapper
                  visible={visible.subCaption}
                  customOpacity={0.5}
                >
                  <SectionDescription>
                    We Craft foundation hidden behind every successful product
                    development by establishing communication and calendar with
                    reporting from birds eye view on project to daily stand ups
                    and progress track
                  </SectionDescription>
                </AnimationWrapper>
              </VisibilitySensor>
            </ProjectSectionHeading>
            <DesktopWrapper>
              <ProjectCard
                backgroundImg="/assets/images/DC-Web.png"
                name="Premeet"
                redirectUrl="https://www.digitalclipboard.com/"
              >
                <ProjectIcon
                  src="/assets/icons/dc-logo.png"
                  alt="Digital Clipboard"
                  large
                />
                <ProjectHeading>
                  <ProjectTitle>Premeet</ProjectTitle>
                  <ProjectDescription>
                    Accesible from any device, we’ve made it both safe and easy
                    to connect with new clients online
                  </ProjectDescription>
                </ProjectHeading>
                <BoxNav>
                  <ProjectTag>Web Development</ProjectTag>
                  <ProjectTag morePadding>B2B</ProjectTag>
                </BoxNav>
              </ProjectCard>
            </DesktopWrapper>
            <MobileWrapper>
              <ProjectCard
                backgroundImg="/assets/images/Stakester-1.png"
                name="Stakester"
                redirectUrl="https://www.stakester.com/"
                hasMargin
              >
                <ProjectIcon
                  src="/assets/images/red-cover-logo.svg"
                  alt="Stakester"
                />
                <ProjectHeading topPosition="14.3%">
                  <ProjectDescription>
                    Enter the Stakester arena to play your favourite online
                    games
                  </ProjectDescription>
                </ProjectHeading>
                <BoxNav>
                  <ProjectTag>Web Development</ProjectTag>
                  <ProjectTag morePadding>Mobile</ProjectTag>
                </BoxNav>
              </ProjectCard>
            </MobileWrapper>
          </Column>
          <Column>
            <MobileWrapper>
              <ProjectCard
                backgroundImg="/assets/images/DC-Web.png"
                name="Premeet"
                redirectUrl="https://www.digitalclipboard.com/"
              >
                <ProjectIcon
                  src="/assets/icons/dc-logo.png"
                  alt="Digital Clipboard"
                  large
                />
                <ProjectHeading>
                  <ProjectTitle>Premeet</ProjectTitle>

                  <ProjectDescription>
                    Accesible from any device, we’ve made it both safe and easy
                    to connect with new clients online
                  </ProjectDescription>
                </ProjectHeading>
                <BoxNav>
                  <ProjectTag>Web Development</ProjectTag>
                  <ProjectTag morePadding>B2B</ProjectTag>
                </BoxNav>
              </ProjectCard>
            </MobileWrapper>
            <DesktopWrapper>
              <ProjectCard
                backgroundImg="/assets/images/Stakester-1.png"
                name="Stakester"
                redirectUrl="https://www.stakester.com/"
                hasMargin
              >
                <ProjectIcon
                  src="/assets/images/red-cover-logo.svg"
                  alt="Stakester"
                />
                <ProjectHeading topPosition="14.3%">
                  <ProjectDescription>
                    Enter the Stakester arena to play your favourite online
                    games
                  </ProjectDescription>
                </ProjectHeading>
                <BoxNav>
                  <ProjectTag>Mobile</ProjectTag>
                  <ProjectTag>Web Development</ProjectTag>
                </BoxNav>
              </ProjectCard>
            </DesktopWrapper>
            <ProjectCard
              backgroundImg="/assets/images/DC-APP.png"
              name="Digital Clipboard"
              redirectUrl="https://www.digitalclipboard.com/"
            >
              <ProjectIcon
                src="/assets/icons/dc-logo.png"
                alt="Digital Clipboard"
                large
              />
              <ProjectHeading>
                <ProjectTitle>Clipboard</ProjectTitle>

                <ProjectDescription>
                  Fully customisable and secure client onboarding solution for
                  advisors improve customer experience and eliminate admin
                </ProjectDescription>
              </ProjectHeading>
              <BoxNav>
                <ProjectTag>CMS Dashboard</ProjectTag>
                <ProjectTag>Cloud Development</ProjectTag>
              </BoxNav>
            </ProjectCard>
          </Column>
        </ProjectWrapper>
      </Container>
    </ProjectContainer>
  );
};

export default Projects;
