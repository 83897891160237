import styled from 'styled-components';

export const JobRow = styled.div`
  padding: 0 14px;
  width: 100%;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  :last-child {
    margin-bottom: 0;
  }
  :hover {
    background-color: #e5e5f4;
  }
  /* ${(props) => props.theme.mobileL` */
    padding: 0 9px;
    border-bottom: 1px solid #e5e5f4;
    :first-child {
      border-top: 1px solid #e5e5f4;
    }
  /* `} */
`;

export const JobMain = styled.div`
  /* height: 100%; */
  display: flex;
  align-items: center;
`;

export const JobTitle = styled.h2`
  font-family: Helvetica-nm;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-right: 10px;
`;

export const JobDate = styled.span`
  font-family: Helvetica-n;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8e8e96;
  ${(props) => props.theme.mobileL`
    display: none;
  `}
`;

export const LocationsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  ${(props) => props.theme.mobileL`
    flex-direction: row;
    margin-bottom: 10px;
  `}
`;
