import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { useMediaQuery } from 'react-responsive';

import {
  TeamContainer,
  SectionHeading,
  TeamSectionTitle,
  TeamSectionSubTitle,
  TeamBackground,
  Dot,
  MembersWrapper,
  MembersContainer,
} from './styles';
import { members, constantMembers } from './members';
import TeamMember from '../team-member/TeamMember';
import { AnimationWrapper } from '../common/styles';

const Team = () => {
  const [index, setIndex] = useState(0);
  const [visible, setVisible] = useState({
    caption: false,
    subCaption: false,
    background: false,
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 425px)',
  });

  useEffect(() => {
    const teamInterval = setInterval(() => {
      setIndex((curr) => {
        const numToAdd = isMobile ? 4 : 2;
        const maxlength = isMobile ? members.length - 5 : members.length - 3;
        if (curr >= maxlength) return 0;
        return curr + numToAdd;
      });
    }, 3000);
    return () => clearInterval(teamInterval);
  }, [isMobile]);

  return (
    <TeamContainer id="team">
      <VisibilitySensor
        partialVisibility={true}
        onChange={(isVisible) => {
          if (isVisible && !visible.background) {
            setVisible((curr) => ({ ...curr, background: true }));
          }
        }}
      >
        <TeamBackground
          src={'/assets/images/team-bg.svg'}
          alt="team"
          visible={visible.background}
        />
      </VisibilitySensor>
      <Dot gray />
      <Dot green />
      <Dot pink />
      <SectionHeading>
        <VisibilitySensor
          onChange={(isVisible) => {
            if (isVisible && !visible.caption) {
              setVisible((curr) => ({ ...curr, caption: true }));
            }
          }}
        >
          <AnimationWrapper visible={visible.caption}>
            <TeamSectionTitle>Team members</TeamSectionTitle>
          </AnimationWrapper>
        </VisibilitySensor>
        <VisibilitySensor
          onChange={(isVisible) => {
            if (isVisible && !visible.subCaption) {
              setVisible((curr) => ({ ...curr, subCaption: true }));
            }
          }}
        >
          <AnimationWrapper visible={visible.subCaption} customOpacity={0.6}>
            <TeamSectionSubTitle>
              We Craft foundation hidden behind every successful product
              development establishing
            </TeamSectionSubTitle>
          </AnimationWrapper>
        </VisibilitySensor>
      </SectionHeading>
      <MembersWrapper>
        <MembersContainer>
          {
           constantMembers.map((cm) => {
              return <TeamMember constant key={cm.name} member={cm} />;
            })}
          {members
            .slice(index, index + 2)
            .map((m, i) => {
              return <TeamMember key={i} member={m} />;
            })}
        </MembersContainer>
      </MembersWrapper>
    </TeamContainer>
  );
};

export default Team;
