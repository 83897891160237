import styled, { keyframes, css } from 'styled-components';

export const InnerCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  border-radius: 30px;
`;

export const InnerCardHeader = styled.div`
  height: 100px;
  border-bottom: 1px solid #e5eaec;
  display: flex;
  justify-content: space-between;
  padding-left: 180px;
  align-items: center;
  ${(props) => props.theme.tabletL`
    display: none;
  `}
`;

export const CardHeaderRight = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ArrowSmall = styled.img`
  /* width: 9.1px;
  height: 14.8px; */
  width: 28px;
  height: 28px;
  margin-right: 7.6px;
  /* object-fit: cover; */
`;

export const BackLabel = styled.h2`
  font-family: Helvetica-nm;
  /* medium font */
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #383838;
`;

export const NavigateButton = styled.div`
  height: 100%;
  width: 128px;
  border-left: 1px solid #e5eaec;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ disabled }) => (disabled ? '#a0a0a0' : '#624de3')};
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  ${(props) => props.theme.tabletL`
    flex: 1;
    :first-child {
      border-left: 0;
    }
  `}
`;

export const CardContentAnimation = keyframes`
  from { opacity: 0; }
  to% { opacity: 1; }
`;

export const InnerCardContent = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  max-width: 1560px;
  display: flex;
  justify-content: space-between;
  padding: 0 7.9%;
  padding-top: 7%;
  ${({ updated }) =>
    updated &&
    css`
      animation: ${CardContentAnimation} 0.5s ease-in-out forwards;
    `}
  @media (max-height: 900px) {
    padding-top: 5%;
  }
  ${(props) => props.theme.tabletL`
    height: 100%;
    flex-direction: column;
    padding: unset;
    padding-top: 118px;
  `}
  @media (max-height: 730px) {
    padding-top: 80px;
  }
  @media (max-height: 650px) {
    padding-top: 65px;
  }
`;

export const CardContentRight = styled.div`
  height: 100%;
  width: 51.93%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 13.3%;
  @media (max-height: 1000px) {
    padding-bottom: 8%;
  }
  @media (max-height: 900px) {
    padding-bottom: 7%;
  }
  @media (max-height: 850px) {
    padding-bottom: 5.5%;
  }
  ${(props) => props.theme.laptopM`
    padding-bottom: 7%;
  `}
  ${(props) => props.theme.tabletL`
    width: 100%;
    padding: 0;
  `}
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
`;

export const ServiceName = styled.h2`
  font-family: Eina-b;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: #383838;
  margin-bottom: 25px;
  white-space: pre-line;
  ${(props) => props.theme.laptopM`
    font-size: 32px;
    margin-bottom: 20px;
  `}
  ${(props) => props.theme.tabletL`
    font-family: Eina-sb;
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    color: #000000;
    margin-bottom: 9px;
    text-align: center;
  `}
`;

export const ServiceDescription = styled.p`
  font-family: Helvetica-n;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.2px;
  color: #b3b3b3;
  white-space: pre-line;
  height: 37.6%;
  margin-bottom: 10px;
  @media (max-height: 1050px) {
    font-size: 13px;
    letter-spacing: 0.1px;
  }
  ${(props) => props.theme.tabletL`
    padding: 0 60px;
    opacity: 0.8;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.18px;
    text-align: center;
    color: #9c9c9c;
    height: 100px;
    overflow-y: hidden;
  `}
  ${(props) => props.theme.mobileL`
    padding: unset;
  `}
`;

export const ButtonWrapper = styled.div`
  width: 201px;
  height: 58px;
`;

export const ActionsNav = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme.tabletL`
    display: none;
  `}
`;

export const ShareButton = styled.div`
  cursor: pointer;
  width: 58px;
  height: 58px;
  margin-left: 12px;
  border-radius: 10px;
  background-color: #efeef6;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShareIcon = styled.img`
  width: 19px;
  height: 20px;
`;

export const ServiceInnerImg = styled.img`
  height: 375px;
  max-width: 100%;
  width: auto;
  object-fit: contain;
  user-select: none;
  ${(props) => props.theme.tabletL`
     height: 225px;

  `}
  @media (max-height: 770px) {
    height: 200px;
  }
  @media (max-height: 660px) {
    height: 160px;
  }
  /* @media (max-height: 730px) {
    height: 180px;
  }
  @media (max-height: 650px) {
    height: 150px;
  } */
  /* ${(props) => props.theme.mobileM`
    height: 200px
    max-height: 200px;
  `} */
`;

export const CardContentLeft = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex: 1;
  padding-right: 11%;
  padding-top: 1%;
  ${(props) => props.theme.tabletL`
   min-height:225px;
   padding: unset;
 `}

  @media (max-height: 770px) {
    min-height: 200px;
  }
  @media (max-height: 660px) {
    min-height: 160px;
  }
  /* @media (max-height: 730px) {
    height: 180px;
  }
  @media (max-height: 650px) {
    height: 150px;
  } */

  /* ${(props) => props.theme.mobileS`
    min-height: 200px;
    max-height: 200px;
  `} */
`;

export const NavigateMobileWrapper = styled.div`
  width: 100%;
  display: none;
  height: 105px;
  border-top: 1px solid #e5eaec;
  ${(props) => props.theme.tabletL`
    display: flex;
    width: calc(100% + 16px);
    position: absolute;
    bottom: -10px;
    right: -8px;
  `}
  ${(props) => props.theme.mobileL`
    height: 71px;
    bottom: 0;
  `}
`;
