import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  MobileNavBarContainer,
  MobileNavBarWrapper,
  NavbarMobileContentWrapper,
  CloseIconWrapper,
  CloseIcon,
  MobileMenu,
  MobileMenuList,
  MobileMenuListItem,
  MobileNavBarHeading,
  TabletLogoWrapper,
} from './styles';
import { list } from '../nav-bar/Navbar';
import { navigateToContent } from '../../utils/helpers';
import { Logo, LogoWrapper } from '../header/styles';

const MobileNavBar = ({
  open,
  setOpen,
  contentVisible,
  setDirection,
  setCurrentPosition,
}) => {
  const history = useHistory();
  return (
    <MobileNavBarContainer open={open}>
      <MobileNavBarWrapper />
      <MobileNavBarHeading>
        <TabletLogoWrapper>
          <LogoWrapper>
            <Logo
              src="/assets/images/wecraft-logo.svg"
              alt="We Craft"
              title="We Craft"
            />
          </LogoWrapper>
        </TabletLogoWrapper>
        <CloseIconWrapper onClick={() => setOpen()}>
          <CloseIcon src="/assets/icons/close.svg" alt="close" />
        </CloseIconWrapper>
      </MobileNavBarHeading>
      <NavbarMobileContentWrapper contentVisible={contentVisible}>
        <MobileMenu>
          <MobileMenuList>
            {list.map((item) => {
              return (
                <MobileMenuListItem
                  key={item.mobileLabel}
                  onClick={(e) => {
                    setOpen();
                    if (item.redirectUrl) {
                      setCurrentPosition(null);
                      history.push(item.redirectUrl);
                      return;
                    }
                    setTimeout(() => {
                      navigateToContent(
                        e,
                        item.scrollId,
                        history,
                        setDirection,
                        true,
                        setCurrentPosition,
                      );
                    }, 600);
                  }}
                >
                  {item.mobileLabel}
                </MobileMenuListItem>
              );
            })}
          </MobileMenuList>
        </MobileMenu>
      </NavbarMobileContentWrapper>
    </MobileNavBarContainer>
  );
};

export default MobileNavBar;
