import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { ContentLoadProvider } from './contexts/ContentLoad';

import GlobalStyles from './styles/global-styles';
import Home from './screens/home/Home';
import Header from './components/header/Header';
import Loader from './components/loader/Loader';
import JobList from './screens/job-list/JobList';
import InnerJob from './screens/inner-job.js/InnerJob';

function App() {
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [fontsLoading, setFontsLoading] = useState(true);
  const [videoLoading, setVideoLoading] = useState(true);

  document.fonts.ready.then(() => setFontsLoading(false));

  const isMobile = useMediaQuery({
    query: '(max-width: 425px)',
  });

  return (
    <ContentLoadProvider>
      <GlobalStyles />
      <Router>
        <Loader fontsLoading={fontsLoading} videoLoading={videoLoading} />
        <Header
          burgerMenuOpen={burgerMenuOpen}
          setBurgerMenuOpen={setBurgerMenuOpen}
          contentVisible={contentVisible}
          setContentVisible={setContentVisible}
          setCurrentPosition={setCurrentPosition}
          isMobile={isMobile}
        />

        <Switch>
          <Route
            exact
            path="/jobs"
            render={() => <JobList contentVisible={contentVisible} />}
          />
          <Route
            exact
            path="/jobs/:title"
            render={() => (
              <InnerJob
                contentVisible={contentVisible}
                currentPosition={currentPosition}
                setCurrentPosition={setCurrentPosition}
              />
            )}
          />
          <Route
            exact
            path="/"
            render={() => {
              return (
                <Home
                  contentVisible={contentVisible}
                  currentPosition={currentPosition}
                  fontsLoading={fontsLoading}
                  setVideoLoading={setVideoLoading}
                  videoLoading={videoLoading}
                  setCurrentPosition={setCurrentPosition}
                />
              );
            }}
          />
        </Switch>
      </Router>
    </ContentLoadProvider>
  );
}

export default App;
