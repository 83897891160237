import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { JobRow, JobMain, LocationsWrapper, JobTitle, JobDate } from './styles';
import Location from '../location/Location';

const Job = ({ job }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 425px)',
  });
  const { title, date, locations, redirectParam } = job;
  const history = useHistory();
  return (
    <JobRow onClick={() => history.push(`/jobs/${redirectParam}`)}>
      <JobMain>
        <JobTitle>{title}</JobTitle>
        <JobDate>{date}</JobDate>
      </JobMain>
      {!isMobile && (
        <LocationsWrapper>
          {locations.map((l, i) => {
            return <Location key={i} location={l} />;
          })}
        </LocationsWrapper>
      )}
    </JobRow>
  );
};

export default Job;
