import styled from 'styled-components';

export const ServiceContainer = styled.section`
  height: 100vh;
  min-height: 800px;
  max-height: 1100px;
  width: 100%;
  box-shadow: inset 0 -4px 22px 0 #c9d3e7;
  background-image: linear-gradient(to bottom, #ebeefb, #dbe4f4 68%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${(props) => props.theme.mobileL`
    padding: 7.6% 0;
    min-height: 550px;
  `}
`;

export const ServicesBackground = styled.img`
  position: absolute;
  /* width: 246px; */
  width: 12.3%;
  /* height: 35.6%; */
  /* height: 356px; */
  height: auto;
  bottom: 0;
  /* left: 18px; */
  left: 18px;
  z-index: 4;
  object-fit: cover;
  ${(props) => props.theme.mobileL`
    display: none;
  `}
`;

export const ServiceWrapper = styled.div`
  width: 82.3%;
  height: 82.4%;
  max-width: 1580px;
  box-shadow: 0 30px 150px 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(to bottom, #f0f2f7, #ffffff);
  border-radius: 35px;
  padding: 10px;
  ${(props) => props.theme.tabletL`
  @media all and  (orientation: landscape) {
    height: 550px;
  }`}
  ${(props) => props.theme.mobileL`
    height: 84.8%;
    padding: 8px;
    position: relative;
    width: 87%;
 
  `}
`;

export const ServiceContent = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DotsNav = styled.nav`
  display: flex;
  align-items: center;
  position: absolute;
  top: 40px;
  left: 60px;
  z-index: 3;
  ${(props) => props.theme.mobileL`
    top: 24px;
    left: 20px;
  `}
`;

export const Dot = styled.div`
  width: 16px;
  height: 16px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background: ${({ green, red }) =>
    green ? '#52c22c' : red ? '#ff5a52' : '#e6c029'};
  margin-right: 10px;
  :last-child {
    margin-right: 0;
  }
  ${(props) => props.theme.mobileL`
    width: 14px;
    height: 14px;
    margin-right: 8px;
  `}
`;

export const ServiceHeading = styled.div`
  width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 150px; */
  padding-top: 9.6%;
  @media (max-height: 900px) {
    padding-top: 7%;
  }
  @media (max-height: 850px) {
    padding-top: 5%;
  }
`;

export const Caption = styled.h2`
  font-family: Eina-b;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #383838;
  padding-left: 5px;
`;

export const SubCaption = styled.p`
  opacity: 0.76;
  font-family: Helvetica-n;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #959595;
  width: 100%;
  white-space: pre-line;
`;

export const ServiceCardsNav = styled.div`
  width: 91%;
  min-height: 425px;
  /* min-height: 400px; */
  /* margin-top: 71px; */
  margin: 0 4.4%;
  margin-top: 3%;
  /* margin-bottom: 4%; */
  height: 58%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.theme.laptopM`
    min-height: 400px;
  `}
`;
