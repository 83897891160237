import styled from 'styled-components';
import { DescriptionContainer } from '../timeline-step-description/styles';

export const StepWrapper = styled.li`
  height: 50px;
  display: flex;
  padding: 0 10px;
  margin-top: 2.3%;
  border-radius: 8px;
  position: relative;
  align-items: center;
  background-color: #e8efff;
  width: 0;
  min-width: 0;
  > span {
    opacity: 0;
    flex: 1;
    overflow: hidden;
  }
  margin-left: ${({ position }) => position};
  color: #6251b8;
  cursor: pointer;
  > div {
    background-color: #d8e2f8;
  }
  :first-child {
    margin-top: 0;
  }
  :nth-child(2n) {
    background-color: #d8f3ff;
    color: #1b7eae;
    > div {
      background-color: #c1e5f6;
    }
  }
  :hover {
    ${DescriptionContainer} {
      opacity: 1;
      top: calc(100% + 20px);
    }
    background-color: #d7e1f6;
    > div {
      background-color: #c1cadf;
    }
    :nth-child(2n) {
      background-color: #cbeaf8;
      > div {
        background-color: #b0d8eb;
      }
    }
  }
  transition: width 2s cubic-bezier(0.77, 0, 0.18, 1),
    min-width 2s cubic-bezier(0.77, 0, 0.18, 1);
  /* transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1); */
  ${(props) => props.theme.tabletL`
    margin-top: 24px;
    ${
      props.mobilePosition &&
      `margin-left: calc(${props.mobilePosition} + 60px)`
    };
    :first-child {
      margin-right: calc(100% + 60px)
    }
    `}
  ${({ visible, width, mobileWidth, minWidth, theme }) =>
    visible &&
    `
  width: ${theme.tabletL ? mobileWidth : width};
  min-width: ${minWidth};
  > span {
   opacity: 1;
  }
  `}
`;

export const StepLine = styled.div`
  width: 3px;
  height: 16px;
`;

export const StepLabel = styled.span`
  transition: 2s cubic-bezier(0.77, 0, 0.18, 1);
  flex: 1;
  font-family: Helvetica-nm;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: inherit;
  margin-left: 15px;
  white-space: nowrap;
  ${(props) => props.theme.tabletL`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.16px;
  `}
`;
