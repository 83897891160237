import React from 'react';

import {
  MemberWrapper,
  MemberImage,
  MemberDescriptionBox,
  MemberLabel,
  MemberLabelLight,
} from './styles';

const TeamMember = ({ member, constant }) => {
  const { name, position, avatar } = member;
  return (
    <MemberWrapper {...member}>
      <MemberImage src={avatar} alt={name} constant={constant}/>
      <MemberDescriptionBox>
        <MemberLabel constant={constant}>{name}</MemberLabel>
        <MemberLabel constant={constant} position>
          <MemberLabelLight>{position}</MemberLabelLight>
        </MemberLabel>
      </MemberDescriptionBox>
    </MemberWrapper>
  );
};

export default TeamMember;
