import React, { useState, useEffect } from 'react';

import {
  InnerCardWrapper,
  InnerCardHeader,
  ArrowSmall,
  BackLabel,
  BackButton,
  CardHeaderRight,
  NavigateButton,
  InnerCardContent,
  CardContentRight,
  ServiceName,
  ServiceDescription,
  ButtonWrapper,
  ActionsNav,
  CardContentLeft,
  ServiceInnerImg,
  DescriptionWrapper,
  NavigateMobileWrapper,
} from './styles';
import Button from '../common/Button';
import ArrowIcon from '../common/ArrowIcon';
import { navigateToContent } from '../../utils/helpers';

const navigate = (value, setValue, limit, num) => {
  if (value === limit) return;
  setValue((curr) => curr + num);
};

const ServiceInnerCard = ({
  show,
  active,
  setActive,
  innerServices,
  setChosenService,
  setSelectedServices,
}) => {
  let currentService = null;
  if (typeof active === 'number') {
    currentService = innerServices[active];
  }
  const [updated, setUpdated] = useState(false);
  
  useEffect(() => {
    if (typeof active === 'number') {
      setUpdated(true);
    }
    setTimeout(() => {
      setUpdated(false);
    }, 500);
  }, [active]);


  return (
    <>
      {show && (
        <>
          <InnerCardWrapper>
            <InnerCardHeader>
              <BackButton onClick={() => setActive(null)}>
                <ArrowSmall src="/assets/icons/back-icon.svg" alt="arrow" />
                <BackLabel>Back</BackLabel>
              </BackButton>
              <CardHeaderRight>
                <NavigateButton
                  disabled={active === 0}
                  onClick={() => navigate(active, setActive, 0, -1)}
                >
                  <ArrowIcon
                    width="19px"
                    height="18px"
                    opposite
                    color="currentColor"
                  />
                </NavigateButton>
                <NavigateButton
                  disabled={active === 3}
                  onClick={() => navigate(active, setActive, 3, 1)}
                >
                  <ArrowIcon width="19px" height="18px" color="currentColor" />
                </NavigateButton>
              </CardHeaderRight>
            </InnerCardHeader>
            {currentService && (
              <InnerCardContent updated={updated}>
                <CardContentLeft>
                  <ServiceInnerImg
                    src={currentService.serviceImg}
                    alt={currentService.name}
                  />
                </CardContentLeft>
                <CardContentRight>
                  <DescriptionWrapper>
                    <ServiceName>{currentService.name}</ServiceName>
                    <ServiceDescription>
                      {currentService.description}
                    </ServiceDescription>
                  </DescriptionWrapper>
                  <ActionsNav>
                    <ButtonWrapper>
                      <Button
                        label="WORK WITH US"
                        onClick={(e) => {
                          setChosenService(currentService.id);
                          setSelectedServices([]);
                          navigateToContent(e, 'contact');
                        }}
                      />
                    </ButtonWrapper>
                  </ActionsNav>
                </CardContentRight>
                <NavigateMobileWrapper>
                  <NavigateButton
                    disabled={active === 0}
                    onClick={() => navigate(active, setActive, 0, -1)}
                  >
                    <ArrowIcon
                      width="19px"
                      height="18px"
                      opposite
                      color="currentColor"
                    />
                  </NavigateButton>
                  <NavigateButton
                    disabled={active === 3}
                    onClick={() => navigate(active, setActive, 3, 1)}
                  >
                    <ArrowIcon
                      width="19px"
                      height="18px"
                      color="currentColor"
                    />
                  </NavigateButton>
                </NavigateMobileWrapper>
              </InnerCardContent>
            )}
          </InnerCardWrapper>
        </>
      )}
    </>
  );
};

export default ServiceInnerCard;
