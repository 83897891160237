export const serviceList = [
  {
    name: `Cross-platform 
    Mobile Development`,
    description: `Single Code base for your iOS and 
    Android apps.`,
    serviceImg: `/assets/images/phone.svg`,
    id: 'mobile-development',
  },
  {
    name: `Cloud Development`,
    description: `We craft your personal server space in clouds by Lead  provider. Safety behind firewalls and guarded gateways. Automated pipelines for CICD`,
    serviceImg: `/assets/images/server.svg`,
    id: 'cloud-development',
  },
  {
    name: `Web Development`,
    description: `Handcrafting Single page apps, using cutting 
    edge technologies.`,
    serviceImg: `/assets/images/web.svg`,
    id: 'web-development',
  },
  {
    name: `Team optimization`,
    description: `Code audit, Your Team training
    Agile structuring, Development assistance`,
    serviceImg: `/assets/images/team.svg`,
    id: 'team-optimization',
  },
];

export const innerServices = [
  {
    name: `Cross-platform 
  Mobile Development`,
    description: `In We Craft Mobile Development is about creating a thoughtful, intuitive user experience that translates beautifully across devices, screen sizes, and operating systems. Our team of mobile developers provide trusty experience that will help your product to reach more costumers you have had before. Projects that we have done for Mobile Development shows that our team can assure fast implementation of new features.
   
    We understand the benefits of a mobile-first experience and will use that knowledge to guide your online business towards success.`,
    serviceImg: `/assets/images/mobile-development.png`,
    id: 'mobile-development',
  },
  {
    name: `Cloud Development`,
    description: `We craft your personal server space in clouds by Lead provider. Safety behind firewalls andguarded gateways. Cost optimization. Containerization and container orchestration. Automated pipelines for CICD.
    
    Application development and testing in the cloud are gaining popularity, as more businesses launch public and private cloud computing initiatives. Cloud development typically includes integrated development environments, application lifecycle management components such as test and quality management, source code and configuration management, continuous delivery tools and application security testing components.`,
    serviceImg: `/assets/images/cloud-development.png`,
    id: 'cloud-development',
  },
  {
    name: `Web Development`,
    description: `To We Craft, the web development process is entrancing, because each and every web developing project is a chance to create something interesting and new using cutting-edge web development tools. Our experience in web application development has helped growth of different companies. Our web developers do understand the significance of project quality, that is why they putt all efforts to give a best result possible to our clients.
   
    If your business is looking for a web developers that you can trust, which will make your company look more efficient, than you are in a right place, we can connect you with senior web developers you can depend on.
    `,
    serviceImg: `/assets/images/web-development.png`,
    id: 'web-development',
  },
  {
    name: `Team Optimization`,
    description: `We Craft is a team of strategists, UX experts, technical architects, project managers, and software engineers, our staff is personalized to make your ideas reality, our team has experience in different development platforms, such as : Web, iOS and Android developments, adapted to different device platforms. We provide assign designer and number of developers according to needs of your project. 
    
    Our quality assurance team automates project testing, which makes your product future oriented, this team is responsible to provide user friendly experience, that will simplify making new changes in case you need once we pass you a complete product.
    We Craft team is ready to work in tight deadlines and make your ideas reality.`,
    serviceImg: `/assets/images/team-optimization.png`,
    id: 'team-optimization',
  },
];
