import styled from 'styled-components';

export const FooterContainer = styled.footer`
  height: 100vh;
  min-height: 800px;
  max-height: 1100px;
  padding: 0 100px;
  background: #ffffff;
  ${(props) => props.theme.tabletL`
    padding: 0 65px;
    height: auto;
    min-height: unset;
    max-height: unset;
  `}
  ${(props) => props.theme.mobileL`
    padding: 0 25px;
  `}
`;

export const FooterWrapper = styled.div`
  max-width: 1334px;
  padding-top: 7.5%;
  margin: 0 auto;

  ${(props) => props.theme.tabletL`
    padding-top: 40px;
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  ${(props) => props.theme.tabletL`
    flex-direction: column-reverse;
    justify-content: flex-start;
  `}
`;

export const CaptionWrapper = styled.div`
  margin-left: 561px;
  ${(props) => props.theme.laptopL`
    margin-left: 471px;
  `}
  ${(props) => props.theme.tabletL`
    margin-left: unset;
  `}
`;

export const LeftFooter = styled.div`
  /* padding-top: 118px; */
  display: flex;
  width: 401px;
  margin-right: 160px;
  flex-direction: column;
  justify-content: space-between;
  /* padding-bottom: 14px; */
  padding-bottom: 30px;
  ${(props) => props.theme.laptopL`
    margin-right: 70px;
  `}
  ${(props) => props.theme.tabletL`
    width: 100%;
    padding-bottom: 60px;
    padding-top:35px;
    `}
`;

export const LeftFooterCaption = styled.h2`
  font-family: Eina-sb;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 49px;
  ${(props) => props.theme.tabletL`
    margin-bottom: 28px;
  `}
`;

export const RightFooterCaption = styled.h2`
  font-family: Eina-sb;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  color: #8a8d9c;
  margin-bottom: 6px;
  ${(props) => props.theme.tabletL`
    font-size: 16px;
    letter-spacing: 0.32px;
    margin-bottom: 14px;
  `}
`;

export const RightFooterDesc = styled.p`
  font-family: Eina-sb;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 40px;
  white-space: nowrap;
  ${(props) => props.theme.laptopM`
    white-space: pre-line;
    margin-bottom: 20px;
  `}
  ${(props) => props.theme.tabletL`
    margin-bottom: 70px;
   font-size: 28px;
   `}
`;

export const RightFooterDescBlue = styled.span`
  color: #624de3;
`;

export const AddressNav = styled.div`
  display: flex;
  ${(props) => props.theme.laptopM`
    flex-direction: column;
  `}
  ${(props) => props.theme.tabletL`
    display: none;
  `}
`;

export const TabletAdressNav = styled.div`
  display: none;
  ${(props) => props.theme.tabletL`
   display: block;
  `}
`;

export const TabletAdressRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 710px) {
    flex-direction: column;
    justify-content: unset;
    :first-child {
      margin-bottom: 20px;
    }
  }
`;

export const AddressColumn = styled.div`
  :first-child {
    margin-right: 77px;
  }
  ${(props) => props.theme.laptopM`
    :first-child {
    margin-right: 45px;
  }
    :last-child {
      margin-top: 35px;
    }
  `}
`;

export const AddressWrapper = styled.div`
  height: 85px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 44px;
  max-width: 306px;
  :nth-child(2) {
    margin-bottom: 0;
  }
  ${(props) => props.theme.laptopM`
    margin-bottom: 35px;
    :nth-child(2) {
    margin-bottom: 0;
  }
  `}
`;

export const AddressLabel = styled.p`
  font-family: Eina-sb;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ light }) => (light ? '#8a8d9c' : '#767986')};
  white-space: nowrap;
  ${(props) => props.theme.mobileM`
    font-size: 14px;
  `}
`;

export const AddressLabelDark = styled.span`
  color: #000000;
`;

export const RightFooter = styled.div`
  flex: 1;
  padding-bottom: 30px;
`;

export const RightFooterLine = styled.div`
  height: 1px;
  width: calc(100% + 50px);
  margin-left: -25px;
  display: none;
  background: #e5eaec;
  ${(props) => props.theme.tabletL`
    display: block;
  `}
`;

export const SocialPlatformsNav = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(52px * 3);
  ${(props) => props.theme.tabletL`
    width: 306px;
    flex-wrap:no-wrap;
  `}
  ${(props) => props.theme.mobileL`
    width: unset;
  `}
`;
export const SocialPlatformWrapper = styled.div`
  background-color: #e3e4ea;
  width: 44px;
  height: 44px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 8px 0 0;
  cursor: pointer;
  @media (hover: hover) {
    :hover {
      background-color: #624de3;
    }
  }
  &:active {
    background-color: #624de3;
  }

  :last-child {
    margin-right: 0;
  }
`;

export const LeftFooterCopyRightWrapper = styled.div`
  height: 83px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 201px;
  ${(props) => props.theme.laptopM`
    margin-top: 50px;
  `}
`;

export const CopyRightLabel = styled.p`
  font-family: Eina-sb;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #afb1bb;
  max-width: 401px;
  white-space: pre-line;
  ${(props) => props.theme.tabletL`
    font-size: 14px;
    line-height: 1.64;
  `}
`;

export const CopyRightLabelBlue = styled.span`
  line-height: normal;
  color: #624de3;
  ${(props) => props.theme.tabletL`
    font-size: 16px;
  `}
`;

export const FooterTag = styled.div`
  // max-height: 34px;
  margin-top: 10px;
  margin-right: 8px;
  padding: 10px 22px 10px 22px;
  border-radius: 6px;
  background-color: #eaedf7;
  font-family: Eina-sb;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #616573;
  margin-right: 8px;
  cursor: pointer;

  :hover {
    ${({ selected }) => !selected && `background-color: #d3d6df`}
  }
  ${({ selected }) =>
    selected &&
    `
     box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #624de3;
    color: #ffffff;
  `}
  ${(props) => props.theme.tabletL`
    width: auto;
    white-space: nowrap;
    // :first-child {
    // margin-left: 65px;
    // }
  `}
  ${(props) => props.theme.mobileL`
    // :first-child {
    //   margin-left: 25px;
    // }
  `}
`;

export const ServicesContainer = styled.div`
  margin-bottom: 41px;
  ${(props) => props.theme.laptopM`
    margin-bottom: 20px;
  `}
  ${(props) => props.theme.tabletL`
    margin-bottom: 36px;
  `}
`;

export const SectionLabel = styled.h2`
  font-family: Eina-sb;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 4px;
  ${(props) => props.theme.tabletL`
     font-size: 18px;
  `}
`;

export const TagsNav = styled.div`
  display: flex;
  flex-wrap: wrap;
  ::-webkit-scrollbar {
    display: none;
  }

  /* ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgb(185, 185, 185);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(185, 185, 185);
    border-radius: 10px;
  }
  ::before {
    position: absolute;
    display: block;
    content: '';
    width: 86%;
    height: 2px;
    background: #f7f7f7;
    left: 7%;
    top: -18px;
  } */
  ${(props) => props.theme.tabletL`
    width: 100%;
    flex-wrap: wrap;
    // margin-left: -65px;
    overflow-x: scroll;
  `}
  ${(props) => props.theme.mobileL`
    width: 100%;
    // margin-left: -25px;
  `} /* width: 120%; */
  /* margin-bottom: 6px; */
`;

export const SendButtonWrapper = styled.div`
  margin-top: 25px;
  height: 48px;
  width: 165px;
  /* margin-bottom: 30px; */
  ${(props) => props.theme.tabletL`
    margin-top: 31px;
    margin-bottom: 38px;
    height: 56px;
  `}
  ${(props) => props.theme.tabletL`
    width: 100%;
  `}
`;

export const SmallInputsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 38px;
  margin-top: 51px;
  ${(props) => props.theme.laptopM`
    margin-bottom: 15px;
    margin-top: 32px;
  `}
  ${(props) => props.theme.tabletL`
    flex-direction: column;
    align-items: flex-start;
    margin-top: 62px;
  `}
`;

export const SubmitWrapper = styled.div`
  ${(props) => props.theme.tabletL`
  `}
`;
